import { get } from 'lodash';
import API from 'helpers/api';
import { Navigate } from 'react-router-dom';
import { 
    GET_PROFILE_FAILED,
    GET_PROFILE_SUCCESS,
    PROFILE_UPDATE_FAILED,
    PROFILE_UPDATE_SUCCESS,
    PROFILE_COMMON_ERROR
 } from "./types";
// console.log('Environment : ', process.env.NODE_ENV)
const config = {
  headers: {
    'Content-Type': "application/json; charset=utf-8"
  }
}
function errorRequest(err, dispatch) {
    let data = get(err, 'response.data', null);
    data = data || get(err, 'response');
    data = data || err;
    if(data.error){
        dispatch({
            type: PROFILE_COMMON_ERROR,
            payload: data.error.message,
          });
    }else{
        dispatch({
            type: PROFILE_COMMON_ERROR,
            payload: data.message,
          });
    }
}

export const getprofile = ()  => {
  
    return async (dispatch) => {
      try {
        const config = {
            headers: {
              'Content-Type': "application/json; charset=utf-8"
            }
          }
        const response = await API.apiGet( 'profile', null );
        if (response.data && response.data.profile) {
          await dispatch({ type: GET_PROFILE_SUCCESS, payload: response.data.profile });
        }else{
          await dispatch({ type: GET_PROFILE_FAILED, payload: response.data.message });
        }
      } catch (err) {
        errorRequest(err, dispatch);
      }
    };
}

export const updateProfile = ( payload ) => {
    return async (dispatch) => {
        try {
          const config = {
              headers: {
                'Content-Type': "application/json; charset=utf-8"
              }
            }
          const response = await API.apiPutUrl( 'profile','', payload );
          if (response.data && response.data.success) {
            await dispatch({ type: PROFILE_UPDATE_SUCCESS, payload: response.data.message });
          }else{
            await dispatch({ type: PROFILE_UPDATE_FAILED, payload: response.data.message });
          }
        } catch (err) {
          errorRequest(err, dispatch);
        }
      };
}
export const clearProfileError = () => ({
    type: PROFILE_COMMON_ERROR,
    payload: null
  });
