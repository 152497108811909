import { get } from 'lodash';
import API from 'helpers/api';
import { 
    LIST_GALLERY_INVITATIONS_SUCCESS,
    LIST_GALLERY_INVITATIONS_FAILED,
    GALLERY_INVITATION_ACCEPT_SUCCESS,
    GALLERY_INVITATION_ACCEPT_FAILED,
    GALLERY_INVITATION_COMMON_ERROR,
    SINGLE_GALLERY_SHARE_SUCCESS,
    SINGLE_GALLERY_SHARE_FAILED
} from "./types";

const config = {
  headers: {
    'Content-Type': "application/json; charset=utf-8"
  }
}

function errorRequest(err, dispatch) {
  let data = get(err, 'response.data', null);
  data = data || get(err, 'response');
  data = data || err;
  if(data.error){
    dispatch({
      type: GALLERY_INVITATION_COMMON_ERROR,
      payload: data.error.message,
    });
  }else{
    dispatch({
      type: GALLERY_INVITATION_COMMON_ERROR,
      payload: data.message,
    });
  }
}



/**
 * @function ListInvitations
 * @returns {Array}
 */
export const ListInvitations = (currentPage, searchterm) => {
  return async (dispatch) => {
    try{ 
      const response = await API.apiGetByKey('invitations', `/feeds/${currentPage}`, { search: searchterm });
      if(response.data && response.data.success){
        await dispatch({ type: LIST_GALLERY_INVITATIONS_SUCCESS, payload: response.data })
      }else{
        await dispatch({ type: LIST_GALLERY_INVITATIONS_FAILED, payload: response.data.message });
      }
    } catch (error) {
      errorRequest(error, dispatch);
    }
  }
}

export const acceptInvitation = ( invite_id ) => {
  return async (dispatch) => {
    try{ 
      const response = await API.apiPostUrl('invitations', `/accept`, { invite_id: invite_id });
      if(response.data && response.data.success){
        await dispatch({ type: GALLERY_INVITATION_ACCEPT_SUCCESS, payload: response.data })
      }else{
        await dispatch({ type: GALLERY_INVITATION_ACCEPT_FAILED, payload: response.data.message });
      }
    } catch (error) {
      errorRequest(error, dispatch);
    }
  }
}




