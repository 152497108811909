import { get } from 'lodash';
import API from 'helpers/api';
import { 
  LIST_GALLERY_SUCCESS,
  LIST_GALLERY_FAILED,
  DELETE_GALLERY_SUCCESS,
  DELETE_GALLERY_FAILED,
  CREATE_GALLERY_SUCCESS,
  CREATE_GALLERY_FAILED, 
  PUT_GALLERY_SUCCESS,
  PUT_GALLERY_FAILED,
  GET_ONE_GALLERY_SUCCESS,
  GET_ONE_GALLERY_FAILED,
  GET_VIEW_GALLERY_SUCCESS,
  GET_VIEW_GALLERY_FAILED,
  GALLERY_COMMON_ERROR,
  DELETE_GALLERY_IMAGE_SUCCESS,
  DELETE_GALLERY_IMAGE_FAILED,
  SINGLE_GALLERY_SHARE_SUCCESS,
  SINGLE_GALLERY_SHARE_FAILED,
  LIST_SHARED_GALLERY_SUCCESS,
  LIST_SHARED_GALLERY_FAILED,
  SELECT_IMAGE_ONE,
  CREATE_IMAGE_LIST_COMMENT,
  CLEAR_GALLERY_ERROR
} from "./types";

const config = {
  headers: {
    'Content-Type': "application/json; charset=utf-8"
  }
}

function errorRequest(err, dispatch) {
  let data = get(err, 'response.data', null);
  data = data || get(err, 'response');
  data = data || err;
  if(data.error){
    dispatch({
      type: GALLERY_COMMON_ERROR,
      payload: data.error.message,
    });
  }else{
    dispatch({
      type: GALLERY_COMMON_ERROR,
      payload: data.message,
    });
  }
}


export const updateOneGallery  = (galleryId, payload) => {
  return async (dispatch) => {
    try {
      const response = await API.apiPutUrl('gallery', `/update/${galleryId}`, payload);
      if(response.data && response.data.success){
        await dispatch({ type: PUT_GALLERY_SUCCESS, payload: response.data});
      } else {
        await dispatch({ type: PUT_GALLERY_FAILED, payload: response.data.message });
      }
    } catch (error) {
      errorRequest(error, dispatch);
    }
  }
}
  
/**
 * @function listUser
 * @returns {Array}
 */
export const ListGalleries = (currentPage, searchterm) => {
  return async (dispatch) => {
    try{ 
      const response = await API.apiGetByKey('gallery', `/feeds`, { search: searchterm, currentPage:currentPage });
      if(response.data && response.data.success){
        await dispatch({ type: LIST_GALLERY_SUCCESS, payload: response.data })
      }else{
        await dispatch({ type: LIST_GALLERY_FAILED, payload: response.data.message });
      }
    } catch (error) {
      errorRequest(error, dispatch);
    }
  }
}

export const ListsharedGalleries = (currentPage, searchterm) => {
  return async (dispatch) => {
    try{ 
      const response = await API.apiGetByKey('gallery', `/shared/feeds`, { search: searchterm, currentPage:currentPage });
      if(response.data && response.data.success){
        await dispatch({ type: LIST_SHARED_GALLERY_SUCCESS, payload: response.data })
      }else{
        await dispatch({ type: LIST_SHARED_GALLERY_FAILED, payload: response.data.message });
      }
    } catch (error) {
      errorRequest(error, dispatch);
    }
  }
}

export const clearGalleryError = () => ({
  type: CLEAR_GALLERY_ERROR,
});


export const getOneGallery = (galleryslug, currentPage = 1) => {
  return async (dispatch) => {
    try {
      const response = await API.apiGetByKey('gallery', `/${galleryslug}`, {currentPage:currentPage});
      if(response.data && response.data.success){
        await dispatch({ type: GET_ONE_GALLERY_SUCCESS, payload: response.data }) 
      }else{
        await dispatch({ type: GET_ONE_GALLERY_FAILED, paylaod: response.data.message });
      }
    } catch ( error ){
      errorRequest(error, dispatch)
    }
  }
}

export const viewOneGallery = (gallerySlug, currentPage = 1) => {
  return async (dispatch) => {
    try {
      const response = await API.apiGetByKey('gallery', `/view/${gallerySlug}`, {currentPage:currentPage});
      if(response.data && response.data.success){
        await dispatch({ type: GET_VIEW_GALLERY_SUCCESS, payload: response.data }) 
      }else{
        await dispatch({ type: GET_VIEW_GALLERY_FAILED, paylaod: response.data.message });
      }
    } catch ( error ){
      errorRequest(error, dispatch)
    }
  }
}

export const createGallery = (payload) =>{
    return async (dispatch)=>{
        try{
          
            const response = await API.apiPost('gallery', payload , config);
            if(response.data && response.data.success){
                await dispatch({ type: CREATE_GALLERY_SUCCESS, payload:response.data});
            }else{
                await dispatch({ type: CREATE_GALLERY_FAILED, payload:response.data.message });

            }

        }catch (err){
            errorRequest(err, dispatch);
        }

    }

  }

export const deleteOneGallery = (galleryID) => {
  return async (dispatch) => {
    try {
      const response = await API.apiDeleteUrl('gallery', `/${galleryID}`);
      if(response.data && response.data.success){
        await dispatch({ type: DELETE_GALLERY_SUCCESS, payload: response.data});
      } else {
        await dispatch({ type: DELETE_GALLERY_FAILED, payload: response.data.message });
      }
    } catch (error) {
      errorRequest(error, dispatch);
    }
  }
}

export const deleteOneGalleryImage = (imageID) => {
  return async (dispatch) => {
    try {
      const response = await API.apiDeleteUrl('gallery', `/image/${imageID}`);
      if(response.data && response.data.success){
        await dispatch({ type: DELETE_GALLERY_IMAGE_SUCCESS, payload: response.data});
      } else {
        await dispatch({ type: DELETE_GALLERY_IMAGE_FAILED, payload: response.data.message });
      }
    } catch (error) {
      errorRequest(error, dispatch);
    }
  }
}

export const shareOneGallery = ( payload ) => {
  return async (dispatch) => {
    try {
      const response = await API.apiPostUrl('invitations','/send', payload);
      if(response.data && response.data.success){
        await dispatch({ type: SINGLE_GALLERY_SHARE_SUCCESS, payload: response.data});
      } else {
        await dispatch({ type: SINGLE_GALLERY_SHARE_FAILED, payload: response.data.message });
      }
    } catch (error) {
      errorRequest(error, dispatch);
    }
  }
}

export const updateGalleryImageOne = (image) => {
  return async (dispatch) => {
    try {
      await dispatch({ type: SELECT_IMAGE_ONE, payload: image });
    } catch (err) {
      errorRequest(err, dispatch);
    }
  };
}