import { 
    LIST_USER_SUCCESS,
    LIST_USER_FAILED,
    DELETE_USER_FAILED,
    DELETE_USER_SUCCESS,
    GET_ONE_USER_SUCCESS,
    GET_ONE_USER_FAILED,
    PUT_USER_SUCCESS,
    PUT_USER_FAILED,

    USER_COMMON_ERROR, 
} from "redux/actions/types";

const initialState = {
  error: null,
  create: null,
  users: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LIST_USER_SUCCESS:
        return {
            users: action.payload.users,
        }
    case LIST_USER_FAILED:
        return {
            error: action.payload,
        }
    case DELETE_USER_SUCCESS:
        return {
            deletedUser: action.payload.message,
        }
    case DELETE_USER_FAILED:
        return {
            error: action.payload
        }
    case GET_ONE_USER_SUCCESS:
        return {
            singleUser: action.payload.user
        }
    case GET_ONE_USER_FAILED:
        return {
            error: action.payload
        }
    case PUT_USER_SUCCESS:
        return {
            updatedUser: action.payload.message
        }
    case PUT_USER_FAILED:
        return{
            error: action.payload
        }
    case USER_COMMON_ERROR: 
        return {
            error: action.payload, 
        }
    default: return state;
  }
};
