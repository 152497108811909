import React, { useState } from "react";
import { Carousel, Container, Row, Col } from "react-bootstrap";
import { BsStarFill } from 'react-icons/bs';

const HomeReviews = () => {
    
    const [index, setIndex] = useState(0);
    const handleSelect = (selectedIndex) => {
        setIndex(selectedIndex);
    };

    return (
        <>
            <section className="main--reviews">
                <Container>
                    <Row className="justify-content-center">
                        <Col sm={12} lg={9}>
                            <h2>What Our Customers Say</h2>
                            <Carousel activeIndex={index} onSelect={handleSelect}>
                                <Carousel.Item>
                                    <Carousel.Caption>
                                        <p><span><BsStarFill/><BsStarFill/><BsStarFill/><BsStarFill/><BsStarFill/></span></p>
                                        <p><em>This app is just so awesome! Easy to use and all of the family can see our little girl grow! A great way to share and be connected! Love love love it!!!!</em></p>
                                        <p><strong>Claire</strong></p>
                                    </Carousel.Caption>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Carousel.Caption>
                                        <p><span><BsStarFill/><BsStarFill/><BsStarFill/><BsStarFill/><BsStarFill/></span></p>
                                        <p><em>This app is just so awesome! Easy to use and all of the family can see our little girl grow! A great way to share and be connected! Love love love it!!!!</em></p>
                                        <p><strong>Jonathan</strong></p>
                                    </Carousel.Caption>
                                </Carousel.Item>
                            </Carousel>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default HomeReviews;