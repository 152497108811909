import { 
    LIST_GALLERY_SUCCESS,
    LIST_GALLERY_FAILED,
    DELETE_GALLERY_SUCCESS,
    DELETE_GALLERY_FAILED,
    CREATE_GALLERY_SUCCESS,
    CREATE_GALLERY_FAILED, 
    PUT_GALLERY_SUCCESS,
    PUT_GALLERY_FAILED,
    GET_ONE_GALLERY_SUCCESS,
    GET_ONE_GALLERY_FAILED,
    GET_VIEW_GALLERY_SUCCESS,
    GET_VIEW_GALLERY_FAILED,
    GALLERY_COMMON_ERROR,
    DELETE_GALLERY_IMAGE_SUCCESS,
    DELETE_GALLERY_IMAGE_FAILED,
    SINGLE_GALLERY_SHARE_SUCCESS,
    SINGLE_GALLERY_SHARE_FAILED,
    LIST_SHARED_GALLERY_SUCCESS,
    LIST_SHARED_GALLERY_FAILED,
    LIKE_IMAGE,
    DISLIKE_IMAGE,
    CREATE_IMAGE_LIST_COMMENT,
    DELETE_COMMENT,
    SELECT_IMAGE_ONE,
    CLEAR_GALLERY_ERROR
} from "redux/actions/types";

const initialState = {
    error: null,
    createGallery: null,
    galleries: null,
    sharedgalleries: null,
    getGalleryFeed: null,
    comment: null,
    getOne: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CREATE_GALLERY_SUCCESS :
        return {
            ...state,
            createGallery: action.payload.message,
        };
    case CREATE_GALLERY_FAILED :
        return {
            ...state,
            error: action.payload.message,
        }
    case LIST_GALLERY_SUCCESS:
        return {
            galleries: action.payload.galleryData
        }
    case LIST_GALLERY_FAILED:
        return {
            error: action.payload,
        }
    case LIST_SHARED_GALLERY_SUCCESS:
        return {
            sharedgalleries: action.payload.galleryData
        }
    case LIST_SHARED_GALLERY_FAILED:
        return {
            error: action.payload,
        }
    case DELETE_GALLERY_SUCCESS:
        return {
            deletedGallery: action.payload.message,
        }
    case DELETE_GALLERY_FAILED:
        return {
            error: action.payload
        }
    case DELETE_GALLERY_IMAGE_SUCCESS:
        return {
            deletedGalleryimage: action.payload.message,
        }
    case DELETE_GALLERY_IMAGE_FAILED:
        return {
            error: action.payload
        }
    case GET_ONE_GALLERY_SUCCESS:
        return {
            singleGallery: action.payload.galleryDataSingle,
            galleryimages: action.payload.galleryImages
        }
    case GET_ONE_GALLERY_FAILED:
        return {
            error: action.payload
        }
    case SELECT_IMAGE_ONE:
        //console.log('payload ', action.payload)
        return {
            ...state,
            getOne: action.payload
        }
    case GET_VIEW_GALLERY_SUCCESS:
        return {
            singleGallery: action.payload.galleryDataSingle,
            galleryimages: action.payload.galleryImages
        }
    case GET_VIEW_GALLERY_FAILED:
        return {
            error: action.payload
        }
    case PUT_GALLERY_SUCCESS:
        return {
            updatedGallery: action.payload.message
        }
    case PUT_GALLERY_FAILED:
        return{
            error: action.payload
        }
    case SINGLE_GALLERY_SHARE_SUCCESS:
        return {
            sharedGallery: action.payload.message
        }
    case SINGLE_GALLERY_SHARE_FAILED:
        return{
            error: action.payload
        }
    case GALLERY_COMMON_ERROR: 
        return {
            error: action.payload, 
        }
    case LIKE_IMAGE:
        console.log(state.galleryimages)
        const prev_images  = state.galleryimages.imagesdata.slice(); 
        console.log('Prev Images data: ',prev_images)
   // console.log( 'state ', state, 'action.payload', action.payload )
        // Loop through each object in object1
        for (const image of prev_images) {
            // Find the matching object in object2 based on _id
            if (image._id === action.payload.post._id) {
                // Update the likes array in obj1 with the likes array from object2
                image.likes = action.payload.post.likes;
            }
        }
        const updateddata = [...prev_images]
        console.log('Updated dAta: ', updateddata)
        return {
            ...state,
            galleryimages: {
                ...state.galleryimages,
                imagesdata: updateddata, // Update the imagesdata array
              },
        }
        case CREATE_IMAGE_LIST_COMMENT:
            
            console.log('Ole gi', state);

            const existingComment = state?.getOne?.comments?.find(
                (comment) => comment?._id === action?.payload?.comment?._id
            );
            const updatedPosts = (state.galleryimages.imagesdata || []).map((image) => {
                if (image?._id === action?.payload?.comment?.image?._id) {
                  const existingComment = image.comments.find((comment) => comment._id === action.payload.comment._id);

                //   console.log('existingComment',existingComment)

                  if (existingComment) {
                    console.log('yes exist ', existingComment)
                    return {
                        // ...state.getPostFeed,
                         ...image,
                      };
                  } else {
                    console.log('new image data ',[action.payload.comment, ...image.comments])
                    return {
                        ...image,
                      comments: [action.payload.comment, ...image.comments],
                    };
                  }
                } else {
                  return image;
                }
              })

            if (existingComment) {
                return {
                    ...state,
                    getOne: {
                        ...state.getOne,
                        comments: state?.getOne?.comments?.map((comment) => {
                            if (comment?._id === action.payload.comment._id) {
                                return {
                                    ...comment,
                                    replies: [...action.payload?.comment?.replies],
                                };
                            } else {
                                return comment;
                            }
                        }),
                    }, 
                    // ...state,
                    // getPostFeed: updatedPosts,
                };
            } else {
                if (state.galleryimages.imagesdata) { console.log('yes')
                    return {
                        ...state,
                        galleryimages: {
                            ...state.galleryimages,
                            imagesdata: updatedPosts, // Update the imagesdata array
                        },
                    };
                } 
                else {console.log('nooo')
                    return {
                        ...state,
                        getOne: {
                            ...state.getOne,
                            comments: [action?.payload?.comment, ...state?.getOne?.comments],
                        },
                    };

                }
            }

        case DELETE_COMMENT:
            const { commentId, feedId } = action.payload;
            
            if (state.galleryimages?.imagesdata) {
                // Find the image that matches the feedId
                const updatedImagesData = state.galleryimages.imagesdata.map(image => {
                    if (image._id === feedId) {
                        // Filter out the deleted comment from the comments array
                        const updatedComments = image.comments.filter(comment => comment._id !== commentId);
                        
                        return {
                        ...image,
                        comments: updatedComments,
                        };
                    }
                    return image;
                });
           // console.log('updatedImagesData',updatedImagesData);
                return {
                ...state,
                galleryimages: {
                    ...state.galleryimages,
                    imagesdata: updatedImagesData,
                },
                };
            } else if (state.getOne?.comments) {
                // Update the comments of a single image if it's not in imagesdata
                const updatedComments = state.getOne.comments.filter(comment => comment._id !== commentId);
            
                // return {
                // ...state,
                // getOne: {
                //     ...state.getOne,
                //     comments: updatedComments,
                // },
                // };
            }
              
            return state; // Return the unchanged state if no updates are made
        case CLEAR_GALLERY_ERROR:
            return {
                ...state,
                error: null,
            };
    default: return state;
  }
};
