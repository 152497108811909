import {
    GET_PROFILE_FAILED,
    GET_PROFILE_SUCCESS,
    PROFILE_UPDATE_FAILED,
    PROFILE_UPDATE_SUCCESS,
    PROFILE_COMMON_ERROR
} from "redux/actions/types";


const initialState = {
    error: null,
    profile: null,
};

export default (state = initialState, action) => {
    
    switch (action.type) {
        case GET_PROFILE_SUCCESS:
            return {
                profile: action.payload,
            };
        case GET_PROFILE_FAILED:
            return {
                error: action.payload,
            };
        case PROFILE_COMMON_ERROR:
            return {
                error: action.payload,
            }
        case PROFILE_UPDATE_SUCCESS:
            return {
                updatedprofile: action.payload,
            }
        case PROFILE_UPDATE_FAILED:
            return {
                error: action.payload,
            }
        default: return state;
    }
};
