import { 
    GET_ADMIN_META_SUCCESS,
    GET_ADMIN_META_FAILED,
    META_COMMON_ERROR 
} from "redux/actions/types";

const initialState = {
  adminMeta: null,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ADMIN_META_SUCCESS: 
        return { 
            ...state,
            adminMeta: action.payload.adminMeta
        };
    case GET_ADMIN_META_FAILED: 
        return { 
            error: action.payload, 
        }; 
    case META_COMMON_ERROR: 
        return {
            error: action.payload, 
        }
    default: return state;
  }
};
