import React from "react";
import { Container, Row, Col, ListGroup, Form, Button } from "react-bootstrap";
import { BsArrowRight, BsInstagram, BsFacebook, BsPinterest, BsYoutube } from 'react-icons/bs';

const SiteFooter = () => {

    return (
        <>
            <footer>
                <Container>
                    <Row>
                        <Col sm={6} lg={3}>
                            <a href="/">
                                <img src={require("../../assets/images/alfie-logo.png")} />
                            </a>
                            <p>Alfie Album was created to give parents a secure and easy way to share photos and videos with loved ones. For more on our story, we invite you to read a short message from our founder.</p>
                        </Col>
                        <Col sm={6} lg={3} className="ps-md-5">
                            <h4>Support</h4>
                            <ListGroup>
                                <ListGroup.Item><BsArrowRight/>Customer Support</ListGroup.Item>
                                <ListGroup.Item><BsArrowRight/>Order Status</ListGroup.Item>
                                <ListGroup.Item><BsArrowRight/>Pricing</ListGroup.Item>
                                <ListGroup.Item><BsArrowRight/>Privacy Policy</ListGroup.Item>
                                <ListGroup.Item><BsArrowRight/>Terms of use</ListGroup.Item>
                            </ListGroup>
                        </Col>
                        <Col sm={6} lg={3} className="ps-md-3">
                            <h4>Resources</h4>
                            <ListGroup>
                                <ListGroup.Item><BsArrowRight/>About Us</ListGroup.Item>
                                <ListGroup.Item><BsArrowRight/>FAQ</ListGroup.Item>
                                <ListGroup.Item><BsArrowRight/>Contact Us</ListGroup.Item>
                                <ListGroup.Item><BsArrowRight/>Blog</ListGroup.Item>
                            </ListGroup>
                        </Col>
                        <Col sm={6} lg={3}>
                            <h4>Sign Up For Free</h4>
                            <Form className="mb-4">
                                <Form.Group className="mb-3">
                                    <Form.Control type="email" placeholder="Enter email address" />
                                </Form.Group>
                                <Button variant="primary" type="submit">Submit</Button>
                            </Form>
                            <h4>Follow Us On</h4>
                            <ul className="social--icons">
                                <li><a href="javascript:;"><BsInstagram/></a></li>
                                <li><a href="javascript:;"><BsFacebook/></a></li>
                                <li><a href="javascript:;"><BsPinterest/></a></li>
                                <li><a href="javascript:;"><BsYoutube/></a></li>
                            </ul>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <p className="copyright">Copyright © 2023 Alfie Album. All Rights Reserved</p>
                        </Col>
                    </Row>
                </Container>
            </footer>
        </>
    );
};

export default SiteFooter;