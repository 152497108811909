import React, { useState } from "react";
import { Navbar, Nav, Container, Carousel } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logout } from "redux/actions/auth.actions"
// const logo = require('assets/images/logos/logo.svg');

const Header = ({ isLoggedIn }) => {
    //create initial menuCollapse state using useState hook
    const [menuCollapse, setMenuCollapse] = useState(false)
    const dispatch = useDispatch();
    const { pathname } = useLocation();


    //create a custom function that will change menucollapse state from false to true and true to false
    const menuIconClick = () => {
        //condition checking to change state from true to false and vice versa
        menuCollapse ? setMenuCollapse(false) : setMenuCollapse(true);
    };

    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex) => {
        setIndex(selectedIndex);
    };

    const activeRoute = (menu) => {
        let route = pathname
        const path = [route.split('/').join('')]
        const result = path.some((name) => name.match(menu))
        return result
    }
    const navigate = useNavigate();
    const handleNavigation = (route, event) => {
        console.log('clicked route: ', route)
        event.preventDefault(); // Prevent the default behavior of the link
        navigate(route);

    };

    return (
        <>
            <header>
                <Container>
                    <Navbar expand="lg">
                        <Navbar.Brand href="/">
                            <img src={require("../../assets/images/alfie-logo.png")} />
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav>
                                <Nav.Link onClick={(event) => handleNavigation('/', event)}>Home</Nav.Link>
                                <Nav.Link onClick={(event) => handleNavigation('/about-us', event)}>About Us</Nav.Link>
                                {isLoggedIn ? (
                                    <>
                                        <Nav.Link onClick={(event) => handleNavigation('/dashboard', event)}>Dashboard</Nav.Link>
                                        <Nav.Link onClick={(event) => dispatch(logout())}>Logout</Nav.Link>
                                    </>
                                ) : (
                                    <Nav.Link onClick={(event) => handleNavigation('/login', event)}>Login</Nav.Link>
                                )}
                            </Nav>
                        </Navbar.Collapse>
                    </Navbar>
                </Container>
            </header>
            <Carousel fade activeIndex={index} onSelect={handleSelect}>
                <Carousel.Item>
                    <img src={require("../../assets/images/slide2.jpg")} alt="..." />
                    <Carousel.Caption>
                        <h2>Cherish your tribe and love them</h2>
                        <p>The free, all-in-one resource to securely share your family's photos and videos.</p>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img src={require("../../assets/images/slide1.jpg")} alt="..." />
                    <Carousel.Caption>
                        <h2>Little moments, big memories</h2>
                        <p>Today's beautiful moments are tomorrow's treasured memories</p>
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>
        </>
    );
};

export default Header;