import { useSelector } from "react-redux";
import Header from "components/header";
import AboutHome from "./AboutSection";
import MainFeatures from "./MainFeaturesSection";
import HomeReviews from "./HomeReviews";
import HomeSolutions from "./MainSolutions";
import SiteFooter from "components/footer/FooterMain";


const Home = () => {
    const isLoggedIn = useSelector((state) => state.auth.loggedIn)
    console.log(isLoggedIn)
    return (
        <>
            <Header isLoggedIn={isLoggedIn} />
            <AboutHome />
            <MainFeatures />
            <HomeReviews />
            <HomeSolutions />
            <SiteFooter />
        </>
    )
}

export default Home;