import React, { useEffect, useState } from "react";
import { Container, Row, Form, Button, Col } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import toast, { Toaster } from 'react-hot-toast';

import { login } from 'redux/actions/auth.actions';
import { notValid } from "utils/validations";
import { emailValidation } from "utils/validations";
// const logo = require('assets/images/alfie-logo.png');


const SignIn = (props) => {
    const [user, setUser] = useState({ email:'', password:'' });
    const [error, setError] = useState({ email:'', password:''});
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
     const navigate = useNavigate();
    const apiResult = useSelector(state => state.auth);

    const handleChangeInput = ({ name, value }) => {
        setUser({ ...user, [name]: value})
        setError({ ...error, [name]:''})
    }
    
    const isValid = () => {
        let isValid = true
        let passwordErrorMessage = "Please provide password";
        let emailErrorMessage = "Please provide email";
        if(!emailValidation(user.email)){
            setError({ ...error, ['email']: emailErrorMessage})
            isValid = false
        }

        if(notValid(user.password)){
            setError({ ...error, ['password']: passwordErrorMessage})
            isValid = false
        }

        if(!emailValidation(user.email) && notValid(user.password)){
            setError({ ...error, ['password']: passwordErrorMessage, ['email']: emailErrorMessage})
            isValid = false
        }
        return isValid
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        if(isValid()){
            setLoading(true)
            // console.log('Logged In')
            await dispatch(login(user))
            setLoading(false)
        }
    }


    useEffect(() => {
        if(apiResult.error){
            toast.error(apiResult.error)
        }
      
    }, [apiResult])

    return (
            <Container className="h-100">
                <Row className="justify-content-center align-items-center h-100">
                    <Col sm={12} lg={5}>
                        <div className="formTop p-4 shadow-lg rounded bg-white">
                            <div className="text-center">
                                {/* <img src={logo} className="App-logo mb-3" alt="logo" /> */}
                                <h2>Welcome to FamilyGallery!</h2>
                                <p>Please sign-in to your account</p>
                            </div>
                            <Form onSubmit={onSubmit}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Email address</Form.Label>
                                    <Form.Control 
                                        type="email" 
                                        placeholder="Enter email"
                                        name="email"
                                        value={user.email || ''}
                                        onChange={({ target }) => handleChangeInput(target)} 
                                    />
                                    {error.email && <span className="error">{error.email}</span>}
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control 
                                        type="password" 
                                        placeholder="Password" 
                                        name="password"
                                        value={user.password || ''}
                                        onChange={({ target }) => handleChangeInput(target)}
                                    />
                                    {error.password && <span className="error">{error.password}</span>}
                                </Form.Group>
                                <Row>
                                    {/* <Form.Group className="mb-3 col">
                                        <Form.Check type="checkbox" label="Remember Me" />
                                    </Form.Group> */}
                                    <Form.Group className="mb-3 col text-end">
                                        <Link to="/reset-password">Forgot Password?</Link>
                                    </Form.Group>
                                </Row>
                                <div className="text-center">
                                    <Button variant="primary" type="submit" disabled={loading}>
                                       {loading ? 'Please Wait...' : 'Login'}
                                    </Button>
                                </div>
                                <h6 className="mt-3 text-center">Don't have an account. <Link to="/register">Create an account</Link></h6> 
                            </Form>
                        </div>
                    </Col>
                </Row>
                <Toaster />
            </Container>
    )
}

export default SignIn;