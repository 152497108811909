import React from "react";
import { Navigate } from "react-router-dom";
import SignIn from 'components/authentication/sign-in';
import SignUp from 'components/authentication/sign-up';
import ResetPassword from 'components/authentication/reset-password';
import Dashboard from "components/dashboard";
import GalleryList from "../components/gallery/list";
import GalleryView from "../components/gallery/view";
import AddUpdateGallery from "../components/gallery/create-update";
import GalleryInvites from '../components/gallery/invites';
import Home from '../components/home';
import Profile from '../components/authentication/profile';
import NotFound from "../components/notfound";

const all = 'all' ;
const commonRouter = [
    {
        type: 'other',
        name: 'ROOT',
        key: 'ROOT',
        component: <Home />,
        route: '/',
        access: all,
    },
    {
        type: 'other',
        name: 'Un Match',
        key: "UN_MATCH",
        component: <Navigate to="/404" />,
        route: "*",
        access: all
    } ,
    {
        type: 'notfound',
        name: 'Not Found',
        key: "NOT_FOUND ",
        component: <NotFound />,
        route: "/404",
        access: all
    }
];
const publicRoutes = [
    ...commonRouter,
    {
        type: 'page',
        name: 'sign-in',
        key: 'SIGNIN',
        component: <SignIn/>,
        route: '/login',
        access: all,
    },
    {
        type: 'page',
        name: 'sign-up',
        key: 'SIGNUP',
        component: <SignUp/>,
        route: '/register',
        access: all,
    },
    {
        type: 'page',
        name: 'reset-password',
        key: 'RESETPASSWORD',
        component: <ResetPassword/>,
        route: '/reset-password',
        access: all,
    }
];

const privateRoutes = [
    ...commonRouter,
    {
        type: 'page',
        name: 'dashboard',
        key: 'DASHBOARD',
        component: <Dashboard/>,
        route: '/dashboard',
        access: all,
    },
    {
        type: 'page',
        name: 'gallery',
        key: 'GALLERY',
        component: <GalleryList/>,
        route: '/galleries',
        access: all,
    },
    {
        type: 'page',
        name: 'gallery-create',
        key: 'GALLERY_CREATE',
        component: <AddUpdateGallery/>,
        route: '/gallery/create',
        access: all,
    },
    {
        type: 'page',
        name: 'gallery-edit',
        key: 'GALLERY_EDIT',
        component: <AddUpdateGallery/>,
        route: '/gallery/edit/:galleryslug/',
        access: all,
    },
    {
        type: 'page',
        name: 'gallery-view',
        key: 'GALLERY_VIEW',
        component: <GalleryView/>,
        route: '/gallery/view/:galleryslug',
        access: all,
    },
    {
        type: 'page',
        name: 'my-profile',
        key: 'MY_PROFILE',
        component: <Profile/>,
        route: '/profile',
        access: all,
    },
    {
        type: 'page',
        name: 'gallery-invitation',
        key: 'GALLERY_INVITATION',
        component: <GalleryInvites/>,
        route: '/galleries/invitations',
        access: all,
    },
    {
        type: 'page',
        name: 'sign-in',
        key: 'SIGNIN',
        component:  <Navigate to="/dashboard" />,
        route: '/login',
        access: all,
    },
    {
        type: 'page',
        name: 'sign-up',
        key: 'SIGNUP',
        component:  <Navigate to="/dashboard" />,
        route: '/register',
        access: all,
    },
    
]

const hideSidebarRoutes = [
    '/login',
    '/register',
    '/',
    '/reset-password',
    '/about-us',
    '/404'
]

export { publicRoutes, privateRoutes, hideSidebarRoutes };


// const PublicRoutes = () => {
//     return (
//         <Routes>
//             <Route exact path="/" key="LOGGED_IN_ROOT" element={ <Navigate to="/dashboard" /> } />
//             <Route exact path="/dashboard" key="DASHBOARD" element={ <Dashboard /> } />
//             <Route exact path="/gallery" element={<GalleryList/>}  key="Galleries"/>
//             <Route exact path="/gallery/create" element={<AddUpdateGallery/>}  key="ADD_GALLERY"/>
//             <Route exact path="/gallery/create/:galleryslug/:galleryId" element={<AddUpdateGallery/>}  key="ADD_GALLERY"/>
//             <Route exact path="/gallery/view/:galleryslug/:galleryId" element={<viewGallery/>}  key="VIEW_GALLERY"/>
//             <Route exact path="*" element={<Navigate to="/dashboard" />} />
//         </Routes>
//     )
// }

// class AppRoutes extends Component{
//     render(){
//         return (
//             <div className="App main--wrapper">
//                 <SidebarMenu />
//                 <PublicRoutes />
//             </div>           
//         )   
//     }
// }

// export default AppRoutes;