import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logout } from "redux/actions/auth.actions"
import { Navbar, Nav } from "react-bootstrap";
import { RxDashboard } from 'react-icons/rx';
import { BiMessageSquareDetail } from 'react-icons/bi';
import { RiGalleryLine } from 'react-icons/ri';
import { CiLogout } from 'react-icons/ci';


const Sidebar = () => {
    const navigate = useNavigate();

    const handleNavigation = (route, event) => {
        console.log( 'clicked route: ', route)
        event.preventDefault(); // Prevent the default behavior of the link
        navigate(route);
    };
    
    const dispatch = useDispatch();

    return (
        <>
            <div className="mainSidebar">
                <Navbar bg="light" expand="lg">
                    <Navbar.Brand href="/">
                        <img src={require("../../assets/images/alfie-logo.png")} />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                    <Nav>
                        <Nav.Link onClick={(event) => handleNavigation('/dashboard', event)}><RxDashboard /> Dashboard</Nav.Link>
                        <Nav.Link onClick={(event) => handleNavigation('/galleries', event)}><RiGalleryLine /> Galleries</Nav.Link>
                        <Nav.Link onClick={(event) => handleNavigation('/galleries/invitations', event)}><BiMessageSquareDetail /> Invitation</Nav.Link>
                        <Nav.Link onClick={(event) => dispatch(logout())}><CiLogout /> Logout</Nav.Link>
                    </Nav>
                    </Navbar.Collapse>
                </Navbar>
            </div>
            

        </>
    );
};

export default Sidebar;