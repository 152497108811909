import CryptoJS from 'crypto-js';
import { setAuthorization } from './api';
import io from 'socket.io-client';

export const socket = io(process.env.REACT_APP_API_HOST, {
  transports: ['polling']});

// console.log('process.env.REACT_APP_API_HOST',process.env.REACT_APP_API_HOST)

export function getLanguage() {
  return localStorage.getItem('appLang');
}

export function setLanguage(lang) {
  return localStorage.setItem('appLang', lang);
}

export function getAppId() {
  return process.env.REACT_APP_SECRET_KEY || 'INVALID';
}

export function getToken() {
  return localStorage.getItem('accessToken');
}

export function encodeJWT(payload, KEY = getAppId()) {
  try {
    const encodedPayload = CryptoJS.AES.encrypt(JSON.stringify(payload), KEY).toString();
    const encodedPayloadString = encodedPayload.replace(/\+/g, '~').replace(/\//g, '!').replace(/=/g, '_');
    return encodedPayloadString;
  } catch (error) {
    error.message = 'Data encryption failed';
    return false;
  }
}

export function decodeJWT(initialToken, KEY = getAppId()) {
  try {
    const token = initialToken.replace(/~/g, '+').replace(/!/g, '/').replace(/_/g, '=');
    const bytes = CryptoJS.AES.decrypt(token, KEY);
    const playload = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return playload;
  } catch (error) {
    error.message = 'Invalid secret key';
    return false;
  }
}

export function makeWebId(length) {
  const result = [];
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result.push(characters.charAt(Math.floor(Math.random() * charactersLength)));
  }
  return result.join('');
}

export function currentUser() {
  return JSON.parse(localStorage.getItem('accessToken'));
}

export function isAuth() {
  try {
    const tokenChecked = localStorage.getItem('accessToken');
    if (tokenChecked) {
      return decodeJWT(tokenChecked);
    }
    return false;
  } catch (err) {
    return false;
  }
}

export function login(token, appId = '') {
  localStorage.setItem('accessToken', token);
  localStorage.setItem('appId', appId);
  setAuthorization();
  return true;
}

export function setRemember(user = {}) {
  localStorage.setItem('userRemember', JSON.stringify(user || isAuth()));
  return true;
}

export function removeRemember() {
  localStorage.removeItem('userRemember');
  return true;
}

export function logout() {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('appId');
  setAuthorization();
  setTimeout(() => {
    window.location.href = `${window.location.origin}/`;
  }, 500);
  return true;
}

export const SendComment = (text, postId, userId,parentCommentId) => {
  // console.log('text, postId, userId',text, postId, userId,parentCommentId)
  socket.emit('comment', { text, postId, userId,parentCommentId });
};

export const SendReplyComment = (text, postId, userId, parentCommentId) => {
  // console.log('text, postId, userId',text, postId, userId,parentCommentId)
  socket.emit('new_reply', { text, postId, userId,parentCommentId });
};

export const DeleteComment = (commentId,feedId) => {
  // console.log('commentId',commentId)
  socket.emit('delete_comment', { commentId,feedId });
};

export const DeleteNestedComment = (commentId) => {
  socket.emit('delete_nested_comment', { commentId });
};

export const LikeComment = (user, postId) => {
  socket.emit('LikePost', { user, postId });
};

export const DisLikeComment = (user, postId) => {
  socket.emit('disLikePost', { user, postId });
};
