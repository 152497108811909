import React from "react";
import { Container, Row, Col, ListGroup } from "react-bootstrap";

const AboutHome = () => {

    return (
        <>
            <section className="about--section">
                <Container>
                    <Row className="justify-content-center">
                        <Col sm={12} lg={8}>
                            <h2>Why Alfie Album</h2>
                            <p>Alfie Album was created to give parents a secure and easy way to share photos and videos with loved ones. For more on our story, we invite you to read a short message from our founder.</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <ListGroup>
                                <ListGroup.Item>
                                    <span>
                                        <img src={require('../../assets/images/upload-photo.webp')} alt="" />
                                    </span>
                                    <p><strong>Free Unlimited Storage</strong>Upload your family photos and videos at high speed, with no fees or storage limits.</p>
                                </ListGroup.Item>
                                <ListGroup.Item>
                                    <span>
                                        <img src={require('../../assets/images/family-reaction.webp')} alt="" />
                                    </span>
                                    <p><strong>Family Reactions & Responses</strong>Relive memories through a new lens with comment and reactions from loved ones.</p>
                                </ListGroup.Item>
                                <ListGroup.Item>
                                    <span>
                                        <img src={require('../../assets/images/organised.webp')} alt="" />
                                    </span>
                                    <p><strong>Organized for You</strong>Experience a different month with every swipe. Look back on the joys of childhood.</p>
                                </ListGroup.Item>
                            </ListGroup>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default AboutHome;