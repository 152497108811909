import React, {useState, useEffect} from "react";
import { Button, Row, Col, Form, Container } from "react-bootstrap";
import TopBar from "components/header/topbar";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getprofile, updateProfile, clearProfileError } from "redux/actions/profile.actions";
import Swal from 'sweetalert';
import toast, { Toaster } from 'react-hot-toast';
import { notValid } from "utils/validations";
const MyProfile = () => {
   
    const [ fields, setFields ] = useState({ email:'', firstname:'', lastname:''});
    
    const [ errors, setErrors ] = useState({ email:'', firstname:'', lastname:''});
    const [ loader, setLoader ] = useState(false);
    const [ disable, setDisable ] = useState(true);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    const apiError = useSelector(state => state.profile.error);
    const updateSuccess = useSelector(state => state.profile.updatedprofile);
    const myprofile = useSelector(state => state.profile.profile);
    const [ profile, setProfile ] = useState(null);
    
    const handleProfile = async () => { 
        await dispatch( getprofile() );
    }
    useEffect(() => {
        if( myprofile ){
            setProfile( myprofile );
            
        }
    }, [ myprofile ])

    useEffect(() => {
        const _hasFilled = Object.values(fields).every(value => value !== '');
        const _hasNoError = Object.values(errors ).every(value => value === '');
    console.log(fields);
        // Combine both conditions to check if there are no errors and the activities array is not empty
        const isValid = _hasNoError;

        if(isValid && _hasFilled){
            setDisable(false)
        }else{
            setDisable(true)
        }
    }, [fields, errors])

    const handleChange = ({ target: { name, value, type, files } }) => {
        
        setFields({ ...fields, [name]: value });
        handleValidation(name, value); // Pass the updated fields to the validation function
      };
      

    const handleValidation = (name, value) => {
        setErrors({ ...errors, [name]: ''})
        let message = '';
        if(name === 'firstname'){
            if(notValid(value)){
                message = "Please enter firstname"
            }
        } if(name === 'lastname'){
            if(notValid(value)){
                message = "Please enter lastname"
            }
        } if(name === 'email'){
            if(notValid(value)){
                message = "Please enter Email"
            }
        }
        setErrors({ ...errors, [name]: message})
    }

    const showError = (name) => {
        if(errors[name]) return ( <span className="error">{errors[name]}</span>)
        return null
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoader(true)
        const formData = {};//new FormData();
        for (const [key, value] of Object.entries(fields)) {
            console.log('Key: ' + key + 'value: '+value)
            formData[key] = value 
        }
        let payload = formData;
       // console.log(payload);
        dispatch(updateProfile(payload))
    }

    useEffect(() => {
        if( profile ){ 
            setFields({ email:profile.email, firstname: (profile.firstname) ? profile.firstname :'', lastname: (profile.lastname) ? profile.lastname : '', password: ''})
        }
        if(apiError){
            setLoader(false);
            toast.error(apiError)
            dispatch(clearProfileError());
        }
        if(updateSuccess){
            setLoader(false)
            toast.success(updateSuccess)
            handleProfile();
        }
       
        
        
    }, [updateSuccess, apiError, profile, dispatch ])
    useEffect(() => {
        // Call your function here
        handleProfile();
      }, []);
      
    return (
        <section className="grid--wrapper">
            <TopBar />
            <div className="profileForm  grid--stack">
            <Container>
                <Row>
                    <Col sm={12}>
                        <Form onSubmit={handleSubmit}>
                            <Row>
                                <Col sm={4}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Email Address</Form.Label>
                                        <Form.Control type="email" required value={fields['email'] || ''} onChange={handleChange} />
                                        {showError('email')}
                                    </Form.Group>
                                </Col>
                                <Col sm={4}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>First Name</Form.Label>
                                        <Form.Control type="text" name="firstname" value={fields['firstname'] || ''} required  onChange={handleChange}/>
                                        {showError('firstname')}
                                    </Form.Group>
                                </Col>
                                <Col sm={4}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Last Name</Form.Label>
                                        <Form.Control type="text" name="lastname" value={fields['lastname'] || ''} required  onChange={handleChange}/>
                                        {showError('lastname')}
                                    </Form.Group>
                                </Col>
                                <Col sm={4}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Password</Form.Label>
                                        <Form.Control type="password" name="password" value={fields['password'] || ''} placeholder="New password"  onChange={handleChange}/>
                                        {showError('password')}
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Button variant="primary" type="submit" disabled={disable || loader}>
                                {loader ? 'Please Wait...' : 'Save Changes'}
                            </Button>
                        </Form>
                    </Col>
                </Row>
                <Toaster />
            </Container>
            </div>
        </section>
    )
}

export default MyProfile;