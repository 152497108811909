import React, { useEffect, useState } from 'react';
import { Form } from "react-bootstrap";
import ReactCodeInput from 'react-code-input';
import { emailValidation } from 'utils/validations';
import { requestOtp, verifyOtp } from 'redux/actions/auth.actions';
import { useDispatch, useSelector } from 'react-redux';
import toast, { Toaster } from 'react-hot-toast';

const timer = 60
const OtpVerify = ({ logo, email, setShowPasswordView, setShowOtpView }) => {
    const dispatch =  useDispatch();
    const [ userOtp, setOtp ] = useState('');
    const [ showResend, setShowResend ] = useState(false);
    const [loading, setLoading] = useState(false);
    const [resendLoader, setResendLoader] = useState(false);

    const check = [null, undefined, 'null', 'undefined', ''];
    const state = useSelector(state => state.auth)
    const requestMessage = useSelector(state => state.auth.requestMessage)

    const verifyMessage = useSelector(state => state.auth.verifyMessage);
    const apiError = useSelector(state => state.auth.error);

    useEffect(() => {
        if(!loading){
            var timeleft = timer;
            var downloadTimer = setInterval(function(){
                timeleft--;
                if(document.getElementById("countdowntimer"))
                    document.getElementById("countdowntimer").textContent = timeleft;
                if(timeleft <= 0){
                    setShowResend(true)
                    clearInterval(downloadTimer);
                }
            },1000);
        }else{
            clearInterval(downloadTimer);
        }
    }, [loading])

    useEffect(() => {

        if(userOtp.length === 6){
            // setDisable(false);
            setLoading(true);
            autoSubmit()
        }
    }, [userOtp])

    const autoSubmit = async () => {
        await dispatch(verifyOtp({ email: email, otp: userOtp }))
    }

    const handleRequestOTP = async () => {
        setResendLoader(true);
        if(check.includes(email) || !emailValidation(email)){
            toast.error('Please provide valid email')
            setResendLoader(false);
        }else{
            await dispatch(requestOtp({ email: email }))
        }
    }

    useEffect(() => {
        toast.dismiss()
        if(apiError){
            toast.error(apiError);
            setLoading(false);
        }
        if(verifyMessage){
            toast.success(verifyMessage);
            setShowPasswordView(true)
            setShowOtpView(false)
            setLoading(false);
        }
        if(requestMessage){
            toast.success(requestMessage)
            setResendLoader(false);
        }
    }, [state])

    return (
        <div className="formTop p-4 shadow-lg rounded">
            <div className="text-center">
                <img src={logo} className="App-logo mb-3" alt="logo" />
                <h2>Verify OTP</h2>
                <p>Enter 6 digit OTP sent on email</p>
            </div>
            <div style={{ display:'flex', justifyContent:'flex-end' }}>
            {!loading && <>
                {!showResend && <p> Resend OTP in <span id="countdowntimer">{timer}</span> Seconds</p>}
                {showResend && <span style={{ cursor: 'pointer' }} onClick={() => resendLoader ? null : handleRequestOTP()}>{resendLoader ? 'Please wait...' : 'Resend'}</span>}
            </>}
            </div>
            <Form className='digit-group'>
                <ReactCodeInput 
                    type='number' 
                    fields={6} 
                    onChange={v => setOtp(v)}
                    disabled={resendLoader}
                />
                <div className="text-center mt-2">
                    {loading && <span>Verifying...</span>}
                </div>
            </Form>
            <Toaster />
        </div>
    )
}

export default OtpVerify;