import React from "react";
import { Container, Row, Col, ListGroup } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";
import { AiTwotoneSetting } from 'react-icons/ai';
// import { useDispatch, useSelector } from "react-redux";
// import { getAdminMeta } from "redux/actions/meta.actions";

const TopBar = () => {
    return (
        <div className="app--topbar">
            <Container>
                <Row className="align-items-center">
                    <Col sm={6}>
                        <h2><span>Good Morning</span> Welcome Back!</h2>
                    </Col>
                    <Col sm={6}>
                        <ListGroup>
                            <ListGroup.Item><Link className="" to="/profile"><AiTwotoneSetting /></Link></ListGroup.Item>
                        </ListGroup>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default TopBar;