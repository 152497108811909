import React from 'react';
import { Container, Row, Col, Button, Nav } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
const NotFound = () => {
    const navigate = useNavigate();
  return (
    <Container className="mt-5">
      <Row>
        <Col md={{ span: 6, offset: 3 }}>
          <div className="text-center">
            <h1 className="display-4">404 - Not Found</h1>
            <p className="lead">The page you are looking for does not exist.</p>
            <Nav.Link onClick={(event) =>  navigate('/')}> Go to home</Nav.Link>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default NotFound;
