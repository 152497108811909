import { combineReducers } from "redux";
import authReducer from "./auth.reducer";
import userReducer from "./user.reducer";
import dashboardReducer from "./dashboard.reducer";
import metaReducer from "./meta.reducer";
import postReducer from './post.reducer';
import galleryReducer from "./gallery.reducer";
import invitationReducer from "./invitation.reducer";
import profileReducer from "./profile.reducer";

export default combineReducers({
  auth: authReducer,
  meta: metaReducer,
  user: userReducer,
  dashboard: dashboardReducer,
  gallery: galleryReducer,
  invite: invitationReducer,
  post: postReducer,
  profile: profileReducer,
});
