import React, {useState, useEffect} from "react";
import { Container, Row, Col, ListGroup, Form, Button, Card, Dropdown } from "react-bootstrap";
import { BsFillBellFill, BsPlusCircle, BsTrash } from 'react-icons/bs';
import { AiTwotoneSetting } from 'react-icons/ai';
import { FaEllipsisV } from 'react-icons/fa';
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createGallery, getOneGallery, updateOneGallery, deleteOneGalleryImage, clearGalleryError } from "redux/actions/gallery.actions";
import { notValid } from "utils/validations";
import Swal from 'sweetalert';
import toast, { Toaster } from 'react-hot-toast';
import jwt_decode from 'jwt-decode';
import TopBar from "components/header/topbar";
function AddUpdateGallery() {
    /** -- Form Input Fields -- */
    const [isUpdateComponent, setIsUpdateComponent] = useState(false)
    const [ fields, setFields ] = useState({ name:''});
    const [ galleryimages, setGalleryImages ] = useState([])
    const [galleryId, setGalleryId ] = useState('');
    /** -- Form Fields Errors -- */
    const [ errors, setErrors ] = useState({ name:''});
    const [ loader, setLoader ] = useState(false);
    const [ disable, setDisable ] = useState(true);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { galleryslug } = useParams();
    const [userId, setuserId] = useState('');
    const apiError = useSelector(state => state.gallery.error);
    const createSuccess = useSelector(state => state.gallery.createGallery);
    const updateGallerySuccess =  useSelector(state => state.gallery.updatedGallery)
    const getSingleGallery = useSelector(state => state.gallery.singleGallery)
    const getgalleryImages = useSelector(state => state.gallery.galleryimages)
    const deletedGalleryImage = useSelector(state => state.gallery.deletedGalleryimage);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [imagePreviews, setImagePreviews] = useState([]);
    const supportedImageExtensions = ["jpg", "jpeg", "png", "gif", "webp"];
    const supportedVideoExtensions = ["mp4", "avi"];
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState('');
    const [pagesToDisplay, setPagetodisplay] = useState([]);
    const handlesingleGallery = async () => {
        await dispatch( getOneGallery( galleryslug ) );
    }

     useEffect(() => {
        if(galleryslug){
            setIsUpdateComponent(true)
            dispatch(getOneGallery(galleryslug));
            console.log('Current Page: ',currentPage);
            const maxVisiblePages = 2; // Maximum number of visible pagination buttons
            const pageno_arr = [];
            

            if (totalPages <= maxVisiblePages) {
                for (let i = 1; i <= totalPages; i++) {
                    pageno_arr.push(i);
                }
            } else {
                const startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
                const endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

                if (startPage > 1) {
                    pageno_arr.push(1);
                    if (startPage > 2) {
                        pageno_arr.push('...');
                    }
                }

                for (let i = startPage; i <= endPage; i++) {
                    pageno_arr.push(i);
                }

                if (endPage < totalPages) {
                    if (endPage < totalPages - 1) {
                        pageno_arr.push('...');
                    }
                    pageno_arr.push(totalPages);
                }
            }
            setPagetodisplay(pageno_arr)
        }
    }, [ galleryslug, dispatch])

    useEffect(() => {
        let token = localStorage.getItem('accessToken')
        let DecodedToken = jwt_decode(token)
        setuserId(DecodedToken.aud)
    }, [])

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber); 
    };

    useEffect(() => {
        if( currentPage !== "" && galleryslug){
            dispatch( getOneGallery(galleryslug, currentPage) );  
        }
        console.log('pagesToDisplay', pagesToDisplay)
    }, [currentPage, pagesToDisplay, dispatch])

    useEffect(() => {
        if(getSingleGallery){
            let galleryData = {
                ...getSingleGallery,
                name: getSingleGallery.name,
            }
            setFields(galleryData)
            setGalleryId( getSingleGallery._id );
        }

        if( getgalleryImages && getgalleryImages.imagesdata?.length ){
            setGalleryImages(getgalleryImages.imagesdata)
            setTotalPages(getgalleryImages.totalPages)
        }
    }, [ getSingleGallery, getgalleryImages ])


    useEffect(() => {
        const _hasFilled = Object.values(fields).every(value => value !== '');
        const _hasNoError = Object.values(errors ).every(value => value === '');
    
        // Combine both conditions to check if there are no errors and the activities array is not empty
        const isValid = _hasNoError;

        if(isValid && _hasFilled){
            setDisable(false)
        }else{
            setDisable(true)
        }
    }, [fields, errors])

    const handleChange = ({ target: { name, value, type, files } }) => {
        if (type === "file") {
            // Handle file input changes (for images)
            if (name === "images[]") {
                // Capture the selected images and update the fields state
                const selectedImages = Array.from(files);
                setFields({ ...fields, images: selectedImages });
                setSelectedFiles([...selectedFiles, ...files]);
                // Create image previews for each selected file
                const previews = [];
                for (let i = 0; i < files.length; i++) {
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        previews.push({
                            dataURL: e.target.result,
                            file: files[i],
                        });
                        if (previews.length === files.length) {
                            setImagePreviews(previews);
                        }
                    };
                    reader.readAsDataURL(files[i]);
                }
            }
          }  else {
            setFields({ ...fields, [name]: value });
        }

        handleValidation(name, value); // Pass the updated fields to the validation function
      };
      

    const handleValidation = (name, value) => {
        setErrors({ ...errors, [name]: ''})
        let message = '';
        if(name === 'name'){
            if(notValid(value)){
                message = "Please enter Gallery name"
            }
        }

        
        setErrors({ ...errors, [name]: message})
    }
    if(deletedGalleryImage){
        toast.dismiss()
        toast.success(deletedGalleryImage);
        handlesingleGallery();
    }

    const showError = (name) => {
        if(errors[name]) return ( <span className="error">{errors[name]}</span>)
        return null
    }
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoader(true)
        const formData = new FormData();
        for (const [key, value] of Object.entries(fields)) {
            if (typeof value === 'object' && key === 'images') {
                value.forEach(element => {
                    formData.append('images[]', element);
                });
            } else {
                formData.append(key, value)
            }
        }
        let payload = formData
        
        if(isUpdateComponent){
            dispatch(updateOneGallery(galleryId, payload))
        }else{
            dispatch(createGallery(payload))
        }
    }

    useEffect(() => {
        
        if(apiError){
            setLoader(false)
            toast.error(apiError)
            dispatch(clearGalleryError());
        }
        if(createSuccess){
            setLoader(false)
            toast.success(createSuccess)
            navigate('/galleries')
        }
        if(updateGallerySuccess){
            toast.success(updateGallerySuccess)
            handlesingleGallery();
        }
        
    }, [createSuccess, apiError, updateGallerySuccess, handlesingleGallery, navigate, dispatch ])
    
    const handleRemove = (indexToRemove) => {
        // Filter out the file to remove from both selectedFiles and imagePreviews
        const updatedSelectedFiles = selectedFiles.filter((_, index) => index !== indexToRemove);
        const updatedImagePreviews = imagePreviews.filter((_, index) => index !== indexToRemove);
    
        setSelectedFiles(updatedSelectedFiles);
        setImagePreviews(updatedImagePreviews);
    };

    const handleDeleteGalleryImage = (image) => {
        Swal({
            title: "Are you sure?",
            text: "Once deleted, Post will not be able to recover",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
        .then((willDelete) => {
            if (willDelete) {
                //setLoader(true)
                dispatch(deleteOneGalleryImage(image._id))
            } 
        });
    }

    return (
        <section className="grid--wrapper gallery--page">
            <TopBar />
            <div className="Add--gym grid--stack">
                <Container>
                    <Row>
                        <Col sm={12}>
                            <h2> {isUpdateComponent ? 'Update Gallery: '+ fields['name'] : 'Create New Gallery'}</h2>
                        </Col>
                    </Row>
                    <Form onSubmit={handleSubmit} >
                      <Form.Control  type="hidden" name="userId" value={userId} />
                       
                        <Row className="align-items-center">
                            <Col sm={12} lg={4}>
                                <Form.Group className="form-group">
                                    <input type="file" name="images[]" multiple accept="image/*,video/*" id="image--upload" hidden onChange={(e) => handleChange(e)} />
                                    <label htmlFor="image--upload" className="label--upload"><BsPlusCircle /> Upload Photos</label>
                                </Form.Group>
                            </Col>
                            <Col sm={12} lg={8}>
                                <Row className="align-items-center">
                                    <Col sm={12} lg={6}>
                                        <Form.Group className="form-group">
                                            {/* <Form.Label>Name</Form.Label> */}
                                            <Form.Control type="text" placeholder="Add gallery name" name="name" value={fields['name'] || ''}
                                        onChange={handleChange} />
                                        {showError('name')}
                                        </Form.Group>
                                    </Col>
                                    <Col sm={12} lg={6}>
                                        <Button 
                                            variant="primary" 
                                            type="submit"
                                            disabled={disable || loader}
                                        >
                                            {loader ? 'Please Wait...' : 'Submit'}
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                            {
                            (selectedFiles &&  selectedFiles.length > 0)
                                ?  
                                <>
                                    <div className="upload--previews">
                                        <h3>Image Previews</h3>
                                        <div className="preview--grid">
                                            {imagePreviews.map((preview, index) => (
                                                <div className="image--preview" key={index}>
                                                    {preview && preview.file ? (
                                                        preview.file.type.startsWith('image/') ? (
                                                            <>
                                                                <img src={preview.dataURL} alt={`Preview ${index}`} width="100" />
                                                                <Button variant="primary" onClick={() => handleRemove(index)}><BsTrash /></Button>
                                                            </>
                                                        ) : preview.file.type.startsWith('video/') ? (
                                                            <>
                                                                <video src={preview.dataURL} alt={`Preview ${index}`} width="200" controls />
                                                                <Button variant="primary" onClick={() => handleRemove(index)}><BsTrash /></Button>
                                                            </>
                                                        ) : (
                                                        <p>Unsupported file type</p>
                                                        )
                                                    ) : null}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </>
                            :   ''    
                        }

                        </Row>
                        
                    </Form>

                    <Row className="mt-4">
                        <div className="col-sm-12">
                            <div className="gallery--grid gallery--create">
                                <Row>
                                    {
                                        (galleryimages &&  galleryimages.length > 0)
                                            ?   galleryimages.map((image, ind) => { 
                                                const fileExtension = image.mimetype.toLowerCase(); // Get the file extension in lowercase
                                                // Check if the file extension is in the list of supported image extensions
                                                const isImage = supportedImageExtensions.includes(fileExtension);
                                                // Check if the file extension corresponds to a video file
                                                const isVideo = supportedVideoExtensions.includes(fileExtension);
                                                    return( <>
                                                        <Col sm={12} lg={3} className="mb-3">
                                                            <Card>
                                                                <Button type="button" variant="primary" onClick={() => handleDeleteGalleryImage(image)}><BsTrash /></Button>
                                                                {isImage ? (
                                                                <Card.Img variant="top" src={(image.media_url) ? image.media_url:''} />
                                                                    ) : isVideo ? (
                                                                    <video controls width="100%" height="auto">
                                                                        <source src={image.media_url} type={`video/${fileExtension}`} />
                                                                            Your browser does not support the video tag.
                                                                    </video>
                                                                    ) : (
                                                                    <p>Unsupported file type</p>
                                                                )}                                                                                    
                                                            </Card>
                                                        </Col>
                                                    </>)
                                        })
                                        :   
                                        <h2 className="mt-5 text-center">No Images in the gallery.</h2>    
                                    }
                                </Row>
                            </div>
                        </div>
                    </Row>
                    <div className="pagination">
                            {
                                (currentPage > 1) ? 
                                <button
                                        key={currentPage - 1}
                                        className="pagination-item"
                                        onClick={() => handlePageChange(currentPage - 1)}
                                    >
                                        Prev
                                    </button>
                                : ''
                            }
                            {pagesToDisplay.map((page, index) => (
                                ( totalPages > 1) ?
                                <button
                                    key={index}
                                    className={`pagination-item ${currentPage === page ? 'active' : ''}`}
                                    onClick={() => handlePageChange(page)}
                                >
                                    {typeof page === 'number' ? page : page} {/* Remove the + 1 */}
                                </button>
                                :''
                            ))}

                            {
                                (currentPage < totalPages) ? 
                                <button
                                        key={currentPage + 1}
                                        className="pagination-item"
                                        onClick={() => handlePageChange(currentPage + 1)}
                                    >
                                        Next
                                    </button>
                                : ''
                            }
                        </div>
                        <Toaster />

                </Container>
            </div>
        </section>
    );
}

export default AddUpdateGallery;