import {useState, useEffect, React} from "react";
import { Container, Button, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { ListInvitations, acceptInvitation } from "redux/actions/invitation.action";
import jwt_decode from 'jwt-decode';
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from 'react-hot-toast';
import TopBar from "components/header/topbar";

function GalleryInvites() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [showDialog, setShowDialog] = useState(true); // State to control dialog visibility
    const [currentPage, setCurrentPage] = useState(1);
    const [ searchTerm, setsearchTerm ] = useState('');
    const [totalPages, setTotalPages] = useState('');
    const [invitationFeeds, setInvitationFeed] = useState([]);
    const InviteacceptSuccess = useSelector(state => state.invite.acceptsuccess);
    const InvitationFeed = useSelector(state => state.invite.invitations);
    const InvitationError = useSelector(state => state.invite.error);
    const [userId, setuserId] = useState('');
    const pagesToDisplay = [];
    const handleListGalleryInvitation = async ( ) => {
        await dispatch( ListInvitations( currentPage, searchTerm ) );
    }

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber); 
    };
    useEffect(() => {
        dispatch ( ListInvitations( currentPage, searchTerm ) )
    }, [currentPage])

    useEffect(() => {
        let token = localStorage.getItem('accessToken')
        let DecodedToken = jwt_decode(token)
        setuserId(DecodedToken.aud)
        dispatch(ListInvitations(currentPage))
    }, [])

    useEffect(() => {
        const check = ['undefined', undefined, 'null', null, '']

        if( InvitationFeed ){
            setInvitationFeed(InvitationFeed.invitationData)
            setTotalPages(InvitationFeed.totalPages)
        }
        console.log('Current Page: ',currentPage);
    const maxVisiblePages = 2; // Maximum number of visible pagination buttons

   

    if (totalPages <= maxVisiblePages) {
        for (let i = 1; i <= totalPages; i++) {
            pagesToDisplay.push(i);
        }
    } else {
        const startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
        const endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

        if (startPage > 1) {
            pagesToDisplay.push(1);
            if (startPage > 2) {
                pagesToDisplay.push('...');
            }
        }

        for (let i = startPage; i <= endPage; i++) {
            pagesToDisplay.push(i);
        }

        if (endPage < totalPages) {
            if (endPage < totalPages - 1) {
                pagesToDisplay.push('...');
            }
            pagesToDisplay.push(totalPages);
        }
    }
    }, [InvitationFeed])

    

    const handleSearchinput = ( { target: { name, value} } ) => {
        if ( value.length >= 3 ){
            setsearchTerm( value );
        }else if( value.length === 0){
            setsearchTerm( '' );
        }
    };

    const acceptInvite = ( invite ) => { 
        dispatch( acceptInvitation( invite._id ) );
    };

    useEffect(() => {
       
        
        if(InvitationError){
            toast.dismiss();
            toast.error(InvitationError) 
            console.log('one');
            handleListGalleryInvitation();
        }
        if(InviteacceptSuccess){
            toast.success(InviteacceptSuccess)
            console.log('kjdhskjfh')
            handleListGalleryInvitation();
        }
    


    }, [ InvitationError, InviteacceptSuccess ])

    
    return (
        <>
            <section className="grid--wrapper gallery--page">
                <TopBar />
                <div className="grid--stack">
                    <Container>
                        <Table striped>
                            <thead>
                                <tr>
                                    <th>Invitation</th>
                                    <th className="text-end">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    {
                                        (invitationFeeds &&  invitationFeeds.length > 0)
                                            ?   invitationFeeds.map((invite, ind) => {
                                                    return( <>
                                                        <td>
                                                            {invite.gallery.author && invite.gallery.firstname && invite.gallery.lastname
                                                            ? `${invite.gallery.firstname} ${invite.gallery.lastname} has invited to view gallery - ${invite.gallery.name}`
                                                            : `User has invited to view gallery - ${invite.gallery.name}`}
                                                        </td>
                                                        <td className="text-end">
                                                            {/* <a onClick={() => acceptInvite(invite)}>Accept</a> */}
                                                            <Button variant="primary" onClick={() => acceptInvite(invite)}>Accept</Button>
                                                            <Button variant="secondary">Decline</Button>
                                                        </td>
                                                    </>)
                                                })
                                            :   
                                        <td className="mt-5 text-center" colSpan={3}>No invitation found</td>        
                                    }
                                </tr>
                            </tbody>
                        </Table>
                        <div className="pagination">
                            {
                                (currentPage > 1) ? 
                                <button
                                        key={currentPage - 1}
                                        className="pagination-item"
                                        onClick={() => handlePageChange(currentPage - 1)}
                                    >
                                        Prev
                                    </button>
                                : ''
                            }
                            {pagesToDisplay.map((page, index) => (
                                ( totalPages > 1) ?
                                <button
                                    key={index}
                                    className={`pagination-item ${currentPage === page ? 'active' : ''}`}
                                    onClick={() => handlePageChange(page)}
                                >
                                    {typeof page === 'number' ? page : page} {/* Remove the + 1 */}
                                </button>
                                :''
                            ))}

                            {
                                (currentPage < totalPages) ? 
                                <button
                                        key={currentPage + 1}
                                        className="pagination-item"
                                        onClick={() => handlePageChange(currentPage + 1)}
                                    >
                                        Next
                                    </button>
                                : ''
                            }
                        </div>
                        <Toaster />      
                    </Container>
                </div>
            </section>
        </>
    )
    
}

export default GalleryInvites;