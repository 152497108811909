import { 
    LIST_POST_SUCCESS,
    LIST_POST_FAILED,
    DELETE_POST_SUCCESS,
    DELETE_POST_FAILED,
    LIKE_POST,
    DISLIKE_POST,
    USER_COMMON_ERROR, 
} from "redux/actions/types";

const initialState = {
  error: null,
  create: null,
  posts: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LIST_POST_SUCCESS:
        return {
            posts: action.payload.posts,
        }
    case LIST_POST_FAILED:
        return {
            error: action.payload,
        }
    case DELETE_POST_SUCCESS:
        return {
            deletedPost: action.payload.message,
        }
    case DELETE_POST_FAILED:
        return {
            error: action.payload
        }
    case USER_COMMON_ERROR: 
        return {
            error: action.payload, 
        }
    case LIKE_POST:
        
        console.log( 'state ', state, 'action.payload', action.payload )
        // if(state.getPostFeed){
        //     const postIndex = state?.getPostFeed?.findIndex(post => post._id === action.payload.post._id);
        //     const copyPosts = state.getPostFeed
        //     copyPosts[postIndex].likes = action.payload?.post?.likes
        //     copyPosts[postIndex].dislikes = action.payload?.post?.dislikes
        //     const updatedPost  = [...copyPosts]
        //     return {
        //         getPostFeed: updatedPost
        //     }
        // }

        // if(state.getOne){
        //     const copyPost = state.getOne
        //     copyPost.likes = action.payload?.post?.likes
        //     copyPost.dislikes = action.payload?.post?.dislikes
        //     const updatedPost = { ...copyPost };
        //     return {
        //         getOne: updatedPost
        //     }
        // }

        // if(state.getPostFeedloggedOut){
        //     const postIndex = state?.getPostFeedloggedOut?.findIndex(post => post._id === action.payload.post._id);
        //     const copyPosts = state.getPostFeedloggedOut
        //     copyPosts[postIndex].likes = action.payload?.post?.likes
        //     copyPosts[postIndex].dislikes = action.payload?.post?.dislikes
        //     const updatedPost  = [...copyPosts]
        //     return {
        //         getPostFeedloggedOut: updatedPost
        //     }
        // }
    default: return state;
  }
};
