module.exports = {
    /** -- ERROR -- */
    // COMMON_ERROR:"COMMON_ERROR",
    /** -- AUTH -- */
    LOGIN_SUCCESS:"LOGIN_SUCCESS",
    LOGIN_FAILED:"LOGIN_FAILED",
    LOGIN_COMMON_ERROR:"LOGIN_COMMON_ERROR",
    LOGOUT:"LOGOUT",
    REGISTER_SUCCESS:"REGISTER_SUCCESS",
    REGISTER_FAILED:"REGISTER_FAILED",

    /** -- META -- */
    GET_ADMIN_META_SUCCESS:"GET_ADMIN_META_SUCCESS",
    GET_ADMIN_META_FAILED:"GET_ADMIN_META_FAILED",
    META_COMMON_ERROR:"META_COMMON_ERROR",
    
    /** -- DASHBOARD -- */
    GET_DASHBOARD_STATS_SUCCESS:"GET_DASHBOARD_STATS_SUCCESS",
    GET_DASHBOARD_STATS_FAILED:"GET_DASHBOARD_STATS_FAILED",
    DASHBOARD_COMMON_ERROR:"DASHBOARD_COMMON_ERROR",

    /** -- USER -- */
    CREATE_USER_SUCCESS:"CREATE_USER_SUCCESS",
    CREATE_USER_FAILED:"CREATE_USER_FAILED",    
    LIST_USER_SUCCESS:"LIST_USER_SUCCESS",
    LIST_USER_FAILED:"LIST_USER_FAILED",
    PUT_USER_SUCCESS:"PUT_USER_SUCCESS",
    PUT_USER_FAILED:"PUT_USER_FAILED",
    DELETE_USER_SUCCESS:"DELETE_USER_SUCCESS",
    DELETE_USER_FAILED:"DELETE_USER_FAILED", 
    GET_ONE_USER_SUCCESS:"GET_ONE_USER_SUCCESS",
    GET_ONE_USER_FAILED:"GET_ONE_USER_FAILED",
    USER_COMMON_ERROR:"USER_COMMON_ERROR",

    /** -- POST -- */  
    LIST_POST_SUCCESS:"LIST_POST_SUCCESS",
    LIST_POST_FAILED:"LIST_POST_FAILED",
    DELETE_POST_SUCCESS:"DELETE_POST_SUCCESS",
    DELETE_POST_FAILED:"DELETE_POST_FAILED", 
    CREATE_LIST_COMMENT:"CREATE_LIST_COMMENT",
    CREATE_POST_LIST_COMMENT:"CREATE_POST_LIST_COMMENT",
    LIKE_POST:"LIKE_POST",
    DISLIKE_POST:"DISLIKE_POST",

    RESET_PASSWORD_SUCCESS:"RESET_PASSWORD_SUCCESS",
    RESET_PASSWORD_FAILED:"RESET_PASSWORD_FAILED",
    REQUEST_OTP_SUCCESS:"REQUEST_OTP_SUCCESS",
    REQUEST_OTP_FAILED:"REQUEST_OTP_FAILED",
    VERIFY_OTP_SUCCESS:"VERIFY_OTP_SUCCESS",
    VERIFY_OTP_FAILED:"VERIFY_OTP_FAILED",

    /**  Speciality */
    CREATE_SPECIALITY:"CREATE_SPECIALITY",
    CREATE_SPECIALITY_FAILED:"CREATE_SPECIALITY_FAILED",
    GET_SPECIALITY:"GET_SPECIALITY",
    GET_SPECIALITY_FAILED:"GET_SPECIALITY_FAILED",
    SPECIALITY_COMMON_ERROR :"SPECIALITY_COMMON_ERROR",
    DELETE_SPECIALITY_SUCCESS:"DELETE_SPECIALITY_SUCCESS",
    DELETE_SPECIALITY_FAILED:"DELETE_SPECIALITY_FAILED" ,
    GET_ONE_SPECIALITY_SUCCESS:"GET_ONE_SPECIALITY",
    GET_ONE_SPECIALITY_FAILED:"GET_ONE_SPECIALITY_FAILED",
    UPDATE_SPECIALITY_SUCCESS:"UPDATE_SPECIALITY_SUCCESS",
    UPDATE_SPECIALITY_FAILED:"UPDATE_SPECIALITY_FAILED",

    /** transaction History  */
    GET_TRANSACTION_HISTORY_SUCCESS:"GET_TRANSACTION_HISTORY_SUCCESS",
    GET_TRANSACTIOn_HISTORY_FAILED:"GET_TRANSACTIOn_HISTORY_FAILED",
    TRANSACTION_COMMON_ERROR:"TRANSACTION_COMMON_ERROR",

    /** -- GALLERY -- */  
    LIST_SHARED_GALLERY_SUCCESS:"LIST_SHARED_GALLERY_SUCCESS",
    LIST_SHARED_GALLERY_FAILED:"LIST_SHARED_GALLERY_FAILED",
    LIST_GALLERY_SUCCESS:"LIST_GALLERY_SUCCESS",
    LIST_GALLERY_FAILED:"LIST_GALLERY_FAILED",
    DELETE_GALLERY_SUCCESS:"DELETE_GALLERY_SUCCESS",
    DELETE_GALLERY_FAILED:"DELETE_GALLERY_FAILED",
    CREATE_GALLERY_SUCCESS:"CREATE_GALLERY_SUCCESS",
    CREATE_GALLERY_FAILED:"CREATE_GALLERY_FAILED", 
    PUT_GALLERY_SUCCESS:"PUT_GALLERY_SUCCESS",
    PUT_GALLERY_FAILED:"PUT_GALLERY_FAILED",
    GET_ONE_GALLERY_SUCCESS:"GET_ONE_GALLERY_SUCCESS",
    GET_ONE_GALLERY_FAILED:"GET_ONE_GALLERY_FAILED",
    GET_VIEW_GALLERY_SUCCESS:"GET_ONE_GALLERY_SUCCESS",
    GET_VIEW_GALLERY_FAILED:"GET_ONE_GALLERY_FAILED",
    GALLERY_COMMON_ERROR:"GALLERY_COMMON_ERROR",
    DELETE_GALLERY_IMAGE_SUCCESS:"DELETE_GALLERY_IMAGE_SUCCESS",
    DELETE_GALLERY_IMAGE_FAILED:"DELETE_GALLERY_IMAGE_FAILED",
    SINGLE_GALLERY_SHARE_SUCCESS:"SINGLE_GALLERY_SHARE_SUCCESS",
    SINGLE_GALLERY_SHARE_FAILED:"SINGLE_GALLERY_SHARE_FAILED",
    LIKE_IMAGE:"LIKE_IMAGE",
    DISLIKE_IMAGE:"DISLIKE_IMAGE",
    CREATE_IMAGE_LIST_COMMENT:"CREATE_IMAGE_LIST_COMMENT",
    DELETE_COMMENT:"DELETE_COMMENT",
    SELECT_IMAGE_ONE:"SELECT_IMAGE_ONE",
    CLEAR_GALLERY_ERROR:"CLEAR_GALLERY_ERROR",
    // Gallery Invitations

    LIST_GALLERY_INVITATIONS_SUCCESS:"LIST_GALLERY_INVITATIONS_SUCCESS",
    LIST_GALLERY_INVITATIONS_FAILED:"LIST_GALLERY_INVITATIONS_FAILED",
    GALLERY_INVITATION_ACCEPT_SUCCESS:"GALLERY_INVITATION_ACCEPT_SUCCESS",
    GALLERY_INVITATION_ACCEPT_FAILED:"GALLERY_INVITATION_ACCEPT_FAILED",
    GALLERY_INVITATION_COMMON_ERROR:"GALLERY_INVITATION_COMMON_ERROR",

    // Profile

    GET_PROFILE_SUCCESS:"GET_PROFILE_SUCCESS",
    GET_PROFILE_FAILED:"GET_PROFILE_FAILED",
    PROFILE_UPDATE_SUCCESS:"PROFILE_UPDATED_SUCCESS",
    PROFILE_UPDATE_FAILED:"PROFILE_UPDATE_FAILED",
    PROFILE_COMMON_ERROR:"PROFILE_COMMON_ERROR",
}  