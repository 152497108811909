import React, {useState, useEffect} from "react";
import { Container, Row, Col, ListGroup, Form, Button, Card, Dropdown, Modal, OverlayTrigger, Tooltip, Spinner  } from "react-bootstrap";
import { BsFillReplyAllFill, BsTrash, BsCalendar4 } from 'react-icons/bs';
import { AiFillLike, AiOutlineComment } from 'react-icons/ai';
import { FaRegCommentDots } from 'react-icons/fa';
import { IoEllipsisHorizontalCircle } from 'react-icons/io5';
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { viewOneGallery, updateGalleryImageOne } from "redux/actions/gallery.actions";
import * as authHelper from '../../../helpers/auth';
import { socket, DeleteComment, SendComment, LikeComment, DisLikeComment, SendReplyComment, DeleteNestedComment, deletePost } from '../../../helpers/auth'
import moment from 'moment';
import toast, { Toaster } from 'react-hot-toast';
import jwt_decode from 'jwt-decode';
import TopBar from "components/header/topbar";
function ViewGallery() {
    const [ galleryimages, setGalleryImages ] = useState([])
    const [ShowCommentModel, setShowCommentModel] = useState(false);
    const [ ShowCommentViewModel, setShowCommentViewModel ] = useState(false);
    const [comments, setComments] = useState([]);
    const [ShowrepliesModel, setShowrepliesModel] = useState(false);
    const [ loader, setLoader ] = useState(false);
    const [activeId, setActiveId] = useState(null);
    const [deletec, setdeletec] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { galleryslug } = useParams();
    const [userId, setuserId] = useState('');
    const apiError = useSelector(state => state.gallery.error);
    const auth = useSelector(state => state.auth);
    const getSingleGallery = useSelector(state => state.gallery.singleGallery)
    const getgalleryImages = useSelector(state => state.gallery.galleryimages)
    const selectedImageFromState = useSelector(state => state.gallery.getOne);
    const selected_image = selectedImageFromState || {}; // Default to an empty object if it's null or undefined
    const [repliesData, setrepliesData] = useState('');
    const handleCloseRepliesModel = () => setShowrepliesModel(false);
    const [dropdownOpen, setDropdownOpen] = useState(null);
    const [openConsultModel , setOpenConsultModel] = useState(false);
    const [userNameloggin, setuserNameloggin] = useState('');
    const supportedImageExtensions = ["jpg", "jpeg", "png", "gif", "webp"];
    const supportedVideoExtensions = ["mp4", "avi"];
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState('');
    const pagesToDisplay = [];

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber); 
    };

    useEffect(() => {
        if( currentPage !== ""){
            dispatch( viewOneGallery(galleryslug, currentPage) );  
        }
    }, [currentPage])

    const handleshowRepliesModel = (data) => {
        setShowrepliesModel(true)
        setrepliesData(data)
    }

    const handleCloseConsultModel = () =>{
        setOpenConsultModel(false)
    }

  
  const handleSelectedImage = (image) => {
    dispatch(updateGalleryImageOne(image));
    console.log(selected_image)
  };
// Use useEffect to log the updated selected_image
useEffect(() => {
    console.log(selected_image);
  }, [selectedImageFromState]);
    const handleCloseCommentModel = () => setShowCommentModel(false);
    const handleShowCommentModel = (image) => {
        handleSelectedImage(image);
        setShowCommentModel(true);
    }

    const handleCloseViewCommentModel = () => setShowCommentViewModel(false);
    const showComments = (image) => {
        handleSelectedImage(image);
        setShowCommentViewModel(true);
    }

    const handleNewComment = (event) => {
        setComments(event.target.value);
    }

    const handleCommentSubmit = async (data) => {
        const newComment = { text: comments };
        SendComment(comments, data._id, userId);
        setComments('');
        //await handleSinglePost()
        handleCloseCommentModel()
        toast.success("comment created successfully");
    };

    const handleReplyCommentSubmit = async (data) => {
        const newComment = { text: comments };
        SendComment(comments, data.image, userId, data._id);
        setdeletec(true)
        setComments('');
        handleCloseRepliesModel()
        toast.success("comment created successfully");
    };


    const handleDelteComment = async (commentId, feedId) => {
        DeleteComment(commentId, feedId)
        setDropdownOpen(false);
    }
    const handleNestedDelteComment = async (commentId) => {
        DeleteNestedComment(commentId)
       // await handleSinglePost();
    }

    const handleLike = async (data) => {
        setLoader(true)
        LikeComment(userId, data._id)
    }

    const handleDisLike = async (data) => {
        setLoader(true)
        DisLikeComment(userId, data._id)
        // await handleSinglePost();
        // setLoader(false)
    }
    const toggleAccordion = (id) => {
        setActiveId(activeId === id ? null : id);
        setComments('');
    }

    const handleComment = (id) => {
        setDropdownOpen(dropdownOpen == id ? null : id)
    }

     useEffect(() => {
        console.log(galleryslug);
        if(galleryslug){
            dispatch( viewOneGallery(galleryslug) );
            console.log('Current Page: ',currentPage);
            const maxVisiblePages = 2; // Maximum number of visible pagination buttons

            

            if (totalPages <= maxVisiblePages) {
                for (let i = 1; i <= totalPages; i++) {
                    pagesToDisplay.push(i);
                }
            } else {
                const startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
                const endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

                if (startPage > 1) {
                    pagesToDisplay.push(1);
                    if (startPage > 2) {
                        pagesToDisplay.push('...');
                    }
                }

                for (let i = startPage; i <= endPage; i++) {
                    pagesToDisplay.push(i);
                }

                if (endPage < totalPages) {
                    if (endPage < totalPages - 1) {
                        pagesToDisplay.push('...');
                    }
                    pagesToDisplay.push(totalPages);
                }
            }
        }
    }, [galleryslug])

    useEffect(() => {
        let token = localStorage.getItem('accessToken')
        let DecodedToken = jwt_decode(token)
        console.log(DecodedToken)
        setuserId(DecodedToken.aud)
        setuserNameloggin(DecodedToken.userName)
    }, [])

    useEffect(() => {
        if( getgalleryImages && getgalleryImages.imagesdata?.length ){
            setGalleryImages(getgalleryImages.imagesdata)
            setTotalPages(getgalleryImages.totalPages)
            console.log('selellds',selected_image)
            console.log('length is: ', selected_image.length)
            if( selected_image ){console.log('yes odhr',getgalleryImages.imagesdata)
                getgalleryImages.imagesdata.map((image, ind) => { 
                    console.log(image._id + '==='+ selected_image._id );
                    if( image._id === selected_image._id ){
                        dispatch(updateGalleryImageOne(image));console.log('yes amtched')
                        return true;
                    }
                    
                });
            }
        }
    }, [ getSingleGallery, getgalleryImages ])


    useEffect(() => {
        if(apiError){
            toast.error(apiError)
        }
    }, [ apiError ])
    
    return (
        <section className="grid--wrapper gallery--page">
            <TopBar />
            <div className="Add--gym grid--stack">
                <Container>
                    <Row>
                        <Col sm={12}>
                            <h2>View Gallery: { ( getSingleGallery ) ? getSingleGallery.name : ''} </h2>
                        </Col>
                    </Row>
                    <div className="gallery--grid view--gallery">
                        <Row className="mt-4">
                            {
                                (galleryimages &&  galleryimages.length > 0)
                                    ?   galleryimages.map((image, ind) => { 
                                            const fileExtension = image.mimetype.toLowerCase(); // Get the file extension in lowercase
                                            // Check if the file extension is in the list of supported image extensions
                                            const isImage = supportedImageExtensions.includes(fileExtension);
                                            // Check if the file extension corresponds to a video file
                                            const isVideo = supportedVideoExtensions.includes(fileExtension);
                                            return( <>
                                                <Col sm={12} lg={12} key={ind}>
                                                    <Card className="mb-3">
                                                            {isImage ? (
                                                                <Card.Img variant="top" src={(image.media_url) ? image.media_url:''} /> 
                                                                ) : isVideo ? (
                                                                    <video controls width="100%" height="auto">
                                                                        <source src={image.media_url} type={`video/${fileExtension}`} />
                                                                        Your browser does not support the video tag.
                                                                    </video>
                                                                ) : (
                                                                    <p>Unsupported file type</p>
                                                            )}
                                                        <Card.Body>
                                                            
                                                            <ListGroup>
                                                                <ListGroup.Item><strong>{image?.comments?.length}</strong> Comments
                                                                    <a className="btn-view-comments" onClick={() => showComments(image)} variant=
                                                                                "primary"> <AiOutlineComment /> View</a>
                                                                </ListGroup.Item>
                                                                {auth.loggedIn ? 
                                                                <ListGroup.Item ><strong>{image?.likes?.length}</strong> Like</ListGroup.Item>
                                                                :
                                                                ''
                                                                }
                                                                
                                                            </ListGroup>
                                                            {auth.loggedIn ?
                                                                <ListGroup className='mt-4 border-top pt-3'>
                                                                    <ListGroup.Item>
                                                                    <Button className="btn-upvoat" onClick={() => handleShowCommentModel(image)} variant={image?.likes?.some((x) => x.author._id == userId) ? "primary" : "outline-primary"}> <FaRegCommentDots /> Write Comment</Button>
                                                                    </ListGroup.Item>
                                                                    <ListGroup.Item>
                                                                        <Button className="btn-upvoat" onClick={() => handleLike(image)} variant={image?.likes?.some((x) => x.author._id == userId) ? "secondary" : "outline-secondary"}> <AiFillLike /> Like</Button>
                                                                    </ListGroup.Item>
                                                                </ListGroup>
                                                                : 
                                                                ''
                                                            }  
                                                        </Card.Body>                
                                                    </Card>
                                                </Col>
                                            </>)
                                        })
                                    :   
                                    <h2 className="mt-5 text-center">No Images in the gallery.</h2>       
                                }
                        </Row>

                        <div className="pagination">
                            {
                                (currentPage > 1) ? 
                                <button
                                        key={currentPage - 1}
                                        className="pagination-item"
                                        onClick={() => handlePageChange(currentPage - 1)}
                                    >
                                        Prev
                                    </button>
                                : ''
                            }
                            {pagesToDisplay.map((page, index) => (
                                ( totalPages > 1) ?
                                <button
                                    key={index}
                                    className={`pagination-item ${currentPage === page ? 'active' : ''}`}
                                    onClick={() => handlePageChange(page)}
                                >
                                    {typeof page === 'number' ? page : page} {/* Remove the + 1 */}
                                </button>
                                :''
                            ))}

                            {
                                (currentPage < totalPages) ? 
                                <button
                                        key={currentPage + 1}
                                        className="pagination-item"
                                        onClick={() => handlePageChange(currentPage + 1)}
                                    >
                                        Next
                                    </button>
                                : ''
                            }
                        </div>

                    </div>

                </Container>
            </div>             
            {auth.loggedIn &&
                <>
                    <Modal
                        className="comment--modal"
                        show={ShowCommentModel}
                        onHide={handleCloseCommentModel}
                        backdrop="static"
                        keyboard={false}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Header closeButton>
                            <h3 className="m-0 p-0">Write a Comment</h3>
                        </Modal.Header>
                        <Modal.Body>
                            <Card className='profile-img-follow border-0 rounded-0'>
                                <Card.Img variant="middle" src={require('../../../assets/images/family-reaction.webp')} />
                                <Card.Body className="p-0">
                                    <Card.Subtitle className="text-muted mt-3 mb-4">
                                        <span>Replying to <a href='#'>{selected_image?.author?.firstname}</a></span>
                                    </Card.Subtitle>
                                    <div className="comment-popup">
                                        <Form.Group className="mb-3 d-flex align-items-center">
                                            <img className="me-3" src={require('../../../assets/images/profile.png')} />
                                            <Form.Control
                                                value={comments}
                                                onChange={handleNewComment} as="textarea" placeholder="Write something here" rows={3} />
                                        </Form.Group>
                                        <div className="text-end">
                                            <Button type="submit" onClick={(() => handleCommentSubmit(selected_image))}>Submit</Button>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                            {/* </Form> */}
                        </Modal.Body>
                    </Modal>

                    <Modal
                        className="comment--modal"
                        show={ShowCommentViewModel}
                        onHide={handleCloseViewCommentModel}
                        backdrop="static"
                        keyboard={false}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Header closeButton>
                            <h3 className="m-0 p-0">View Comment</h3>
                        </Modal.Header>
                        <Modal.Body>
                        {selected_image?.comments?.map((comment, ind) => {
                                            return (
                                                <>
                                                    {
                                                        <div className="comment-section-main">
                                                            <div className="comment-img" key={ind} >
                                                                <img src={require('../../../assets/images/profile.png')} alt='...' />
                                                                <div className="comment--desc">
                                                                    <h4 className="auther-name-commet">{comment?.author?.firstname} {comment?.author?.lastname}</h4>
                                                                    <p className="text-muted"><BsCalendar4 /> {moment(comment?.createdAt).fromNow()}</p>
                                                                    <p className="mb-2">{comment?.text}</p>
                                                                </div>
                                                            </div>
                                                            <div className="text-comment">
                                                                <div className="accordion">                                                                    
                                                                    <div className="accordion-header-main">
                                                                        <span onClick={() => handleshowRepliesModel(comment)}><FaRegCommentDots /> {comment?.replies?.length}</span>
                                                                        <span onClick={() => toggleAccordion(comment?._id)} className="show-replyes"><BsFillReplyAllFill/></span>
                                                                        {/* <FaRegCommentDots /> {comment?.replies?.length} */}
                                                                        {comment?.author?._id === userId && (
                                                                            <span className="delete-comment" onClick={() => handleDelteComment(comment?._id, selected_image._id)}>
                                                                                <BsTrash />
                                                                            </span>
                                                                            // <Dropdown show={dropdownOpen == comment?._id}
                                                                            //     onToggle={() => handleComment(comment._id)}>
                                                                            //     <Dropdown.Toggle id={`${comment?._id}`} variant="secondary">
                                                                            //         <IoEllipsisHorizontalCircle />
                                                                            //     </Dropdown.Toggle>
                                                                            //     <Dropdown.Menu variant="dark">
                                                                            //     </Dropdown.Menu>
                                                                            // </Dropdown>
                                                                        )}
                                                                    </div>
                                                                    {activeId === comment?._id &&
                                                                        <div className="accordion-body-main">
                                                                            {comment?.replies?.map((x) => {
                                                                                return (<>
                                                                                    <div className="nested-comment">
                                                                                        <div className="comment-img">
                                                                                            <img src={require('../../../assets/images/profile.png')} alt='...' />
                                                                                            <div className="comment-desc">
                                                                                                <h4 className="auther-name-commet">{x?.author?.userName ? x?.author?.firstname : null}</h4>
                                                                                                <p className="">{x?.text}</p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </>)
                                                                            })}
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                </>
                                            )
                                        })}
                        </Modal.Body>
                    </Modal>

                    <Modal
                        className="comment--modal"
                        show={ShowrepliesModel}
                        onHide={handleCloseRepliesModel}
                        backdrop="static"
                        keyboard={false}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Header closeButton>
                            <h3 className="m-0 p-0">Write a Comment</h3>
                        </Modal.Header>
                        <Modal.Body>
                            <Card className='profile-img-follow border-0 rounded-0'>
                            <Card.Img variant="middle" src={require('../../../assets/images/family-reaction.webp')} />
                                <Card.Body className="p-0">
                                    <Card.Title className='auther-name'>{repliesData?.author?.firstname}</Card.Title>
                                    <Card.Text className="mb-2 text-muted">
                                        {repliesData?.text}</Card.Text>
                                    <Card.Subtitle className="text-muted mt-3 mb-4">
                                        <span>Replying to <a href='#'>{repliesData?.author?.firstname}</a></span>
                                    </Card.Subtitle>
                                </Card.Body>
                            </Card>
                            <div className="comment-popup">
                                <Form.Group className="mb-3 d-flex align-items-center">
                                    <img className="me-3" src={require('../../../assets/images/profile.png')} />
                                    <Form.Control
                                        value={comments}
                                        onChange={handleNewComment} as="textarea" placeholder="Write something here" rows={3} />
                                </Form.Group>
                                <div className="submit-btn-comment text-end">
                                    <Button type="submit" onClick={(() => handleReplyCommentSubmit(repliesData))}>Submit</Button>
                                </div>
                            </div>
                            {/* </Form> */}
                        </Modal.Body>

                    </Modal>
                </>
            }
        </section>
    );
}

export default ViewGallery;