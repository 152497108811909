import React, { useEffect, useState } from "react";
import { Container, Row, Form, Button, Col } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import toast, { Toaster } from 'react-hot-toast';
import { notValid } from "utils/validations";
import { authregister } from 'redux/actions/auth.actions';
const logo = require('assets/images/alfie-logo.png');

const SignUp= (props) => {
   
    const [ fields, setFields ] = useState({ firstname:'', lastname: '', email:'', password:'' });
    /** -- Form Fields Errors -- */
    const [ errors, setErrors ] = useState({ firstname:'', lastname: '', email:'', password:'' });
    const [ loader, setLoader ] = useState(false);
    const [ disable, setDisable ] = useState(true);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const apiResult = useSelector(state => state.auth);


    useEffect(() => {
        const { lastname , ...rest} = fields;
        console.log('errr',errors);
        const _hasFilled = Object.values(lastname ? fields : rest).every(value => value !== '');
        const _hasNoError = Object.values(errors ).every(value => value === '');
       
      
        const isValid = _hasNoError;

        if(isValid && _hasFilled){
            setDisable(false)
        }else{
            setDisable(true)
        }
    }, [fields, errors]);

    const handleChange = ({ target: { name, value  } }) => {
        setFields({ ...fields, [name]: value });
        handleValidation(name, value); // Pass the updated fields to the validation function
      };
      

    const handleValidation = (name, value) => {
        setErrors({ ...errors, [name]: ''})
        let message = '';
        if(name === 'firstname'){
            if(notValid(value)){
                message = "Please enter firstname"
            }
        }


        if(name === 'email'){
            if(notValid(value)){
                message = "Please enter email"
            }
        }

        if(name === 'password'){
            if(notValid(value)){
                message = "Please enter password"
            }
        }
        setErrors({ ...errors, [name]: message})
    }

    const showError = (name) => {
        if(errors[name]) return ( <span className="error">{errors[name]}</span>)
        return null
    }

    const handleSubmit = async (e) => {console.log('submit');
        e.preventDefault();
        setLoader(true)
        const jsonPayload = {};
        for (const [key, value] of Object.entries(fields)) {
            jsonPayload[key] = value;
        }
        await dispatch(authregister(jsonPayload))
        setLoader(false)
        
    }

    useEffect(() => {
        if(apiResult.error){
            toast.error(apiResult.error)
        }else if( apiResult.token ){
           toast.success('Registration Successfull.') ;
           
        }
      
    }, [apiResult])

    return (
            <Container className="h-100">
                <Row className="justify-content-center align-items-center h-100">
                    <Col sm={12} lg={5}>
                        <div className="formTop p-4 shadow-lg rounded bg-white">
                            <div className="text-center">
                                
                                <h2>Sign Up to Family Gallery!!</h2>
                                <p>Please sign-up to create new account and start the adventure</p>
                            </div>
                            <Form onSubmit={handleSubmit} >
                                <Form.Group className="mb-3">
                                    <Form.Label>Firstname</Form.Label>
                                    <Form.Control type="text" name="firstname" placeholder="Enter firstname" onChange={handleChange} />
                                    {showError('firstname')}
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Lastname</Form.Label>
                                    <Form.Control type="text" name="lastname" placeholder="Enter lastname"  value={fields['lastname'] || ''} onChange={(e) => handleChange(e)} />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Email Address</Form.Label>
                                    <Form.Control type="email" name="email" placeholder="Enter email" value={fields['email'] || ''} onChange={(e) => handleChange(e)} />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control type="password" name="password" placeholder="Password"  value={fields['password'] || ''} onChange={(e) => handleChange(e)} />
                                </Form.Group>
                                    <Form.Group className="mb-3">
                                        <label><input type="checkbox" /> I agree to <a href="#">Terms & Conditions</a></label>
                                    </Form.Group>
                                <div className="text-center">
                                    <Button variant="primary" type="submit" disabled={disable || loader}>
                                    {loader ? 'Please Wait...' : 'Register'}
                                    </Button>
                                </div>
                                <h6 className="mt-3 text-center">Already have an account. <Link to="/login">Login instead</Link></h6>
                            </Form>
                        </div>
                    </Col>
                </Row>
                <Toaster />
            </Container>
    )
}

export default SignUp;