import React, { Component } from 'react';
import { connect } from 'react-redux';
import { privateRoutes, publicRoutes, hideSidebarRoutes } from './routes';
import { Routes, Route } from 'react-router-dom';
import { withRouter } from "components/wrapper";
import SidebarMenu from 'components/sidebar';

import * as auth from './helpers/auth';
import toast, { Toaster } from "react-hot-toast";
import { socket, isAuth } from './helpers/auth'
import {CREATE_IMAGE_LIST_COMMENT, DELETE_COMMENT, LIKE_IMAGE, DISLIKE_POST, DELETE_POST_SUCCESS, DELETE_POST_FAILED} from "./redux/actions/types";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-pro-sidebar/dist/css/styles.css';
import './App.css';
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hideSideBar: true,
      loggedIn: false,
    };
  }
  getRoutes = (routes, _privateRoute) =>
    routes.map((route, _privateRoute) => {
      if (route.route) {
        return <Route path={route.route} element={route.component} key={route.key} />;
      }
      return null;
    });
  componentDidMount = () => {
    this.checkIfSidebarShouldBeVisible();
    const token = isAuth();
    if (token) {
      this.setState({ loggedIn: true })
    }
    socket.on('comment_received', data => {
      if (data.success) {
        this.props.createComment(data);
      } else {
        toast.error(data.error);
      }
    });
    socket.on('comment_deleted', (data) => {
      if (data.success) {
        // console.log('deletedata',data)
        this.props.deleteComment(data);
      } else {
        toast.dismiss();
        toast.error(data.error);
      }
    });
    socket.on('LikePost', (data) => {
      console.log(data);
      if (data.success) {
        this.props.likePost(data);
      } else {
        toast.dismiss()
        toast.error(data.error);
      }
    })
    socket.on('disLikePost', data => {
      // console.log( 'Dislike Data ---->>>',  data )
      if (data.success) {
        this.props.dislikePost(data);
      } else {
        toast.dismiss()
        toast.error(data.error);
      }
    })
    socket.on('deletePost', data => {
      // console.log('Post Deleted Response ::',  data )
      if (data.success) {
        this.props.deletePost(data);
      } else {
        toast.dismiss()
        toast.error(data.error);
        this.props.deletePostError(data);
      }
    })
  };
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.checkIfSidebarShouldBeVisible();
    }
  }
  checkIfSidebarShouldBeVisible = () => {
    const { pathname } = this.props.location;
    // const hideSidebarRoutes = ['/login', '/register','/'];
    const hideSideBar = !hideSidebarRoutes.includes(pathname);console.log(hideSideBar);
    this.setState({ hideSideBar });
  };
  render() {
    const islogin = this.props.loggedIn ? this.props.loggedIn: this.state.loggedIn;
    console.log('islogin ',islogin)
    return (
      <div className={(islogin &&  this.state.hideSideBar ) ? "App flex--wrapper logged--in" : 'App main--wrapper'}>
        {this.state.hideSideBar && this.props.loggedIn &&
          <SidebarMenu />
        }
        {
          islogin
            ? <Routes>
              {this.getRoutes(privateRoutes)}
            </Routes>
            : <Routes>
              {this.getRoutes(publicRoutes)}
            </Routes>
        }
        <Toaster />
      </div>
    )
  }
}
const mapDispatchToProps = dispatch => {
  return {
    createComment: async payload => { await dispatch({ type: CREATE_IMAGE_LIST_COMMENT, payload }) },
    // createComment: async payload => { await dispatch({ type: CREATE_POST_LIST_COMMENT, payload }) },
    deleteComment: async payload => { await dispatch({ type: DELETE_COMMENT, payload }) },
    likePost: async payload => { await dispatch({ type: LIKE_IMAGE, payload }) },
    dislikePost: async payload => { await dispatch({ type: DISLIKE_POST, payload }) },
    // deletePost: async payload => { await dispatch({ type: DELETE_POST_SUCCESS, payload}) },
    // deletePostError: async payload => { await dispatch({ type: DELETE_POST_FAILED, payload }) }
  }
}
const mapStateToProps = (state) => {
  console.log(state.auth);
  const { loggedIn } = state.auth; // Make sure to get loggedIn from the correct state slice
  return { loggedIn };
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));