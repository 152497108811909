import { get } from 'lodash';
import API from 'helpers/api';
import { Navigate } from 'react-router-dom';
import * as auth from 'helpers/auth';
import { LOGIN_FAILED, 
  LOGIN_SUCCESS, 
  LOGOUT, 
  LOGIN_COMMON_ERROR, 
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILED,
  REQUEST_OTP_SUCCESS,
  REQUEST_OTP_FAILED,
  VERIFY_OTP_SUCCESS,
  VERIFY_OTP_FAILED,
  REGISTER_SUCCESS,
  REGISTER_FAILED
 } from "./types";
// console.log('Environment : ', process.env.NODE_ENV)
const config = {
  headers: {
    'Content-Type': "application/json; charset=utf-8"
  }
}
function errorRequest(err, dispatch) {
    let data = get(err, 'response.data', null);
    data = data || get(err, 'response');
    data = data || err;
    if(data.error){
        dispatch({
            type: LOGIN_COMMON_ERROR,
            payload: data.error.message,
          });
    }else{
        dispatch({
            type: LOGIN_COMMON_ERROR,
            payload: data.message,
          });
    }
}

export const login = (payload)  => {
  
    return async (dispatch) => {
      try {
        const config = {
            headers: {
              'Content-Type': "application/json; charset=utf-8"
            }
          }
        
        const response = await API.apiPost('login', payload, config)
        if (response.data && response.data.accessToken) {
          auth.login(response.data.accessToken);
         
          await dispatch({ type: LOGIN_SUCCESS, payload: response.data.accessToken });
          
        }else{
          console.log( response.data.message)
          await dispatch({ type: LOGIN_FAILED, payload: response.data.message });
        }
      } catch (err) {
        console.log('sdkhfs');
        errorRequest(err, dispatch);
      }
    };
}

export function logout() {
    return (dispatch) => {
     try {
        auth.logout();
     } catch (err) {
         errorRequest(err, dispatch);
     }
    };
}

export const requestOtp = (payload) => {
  return async (dispatch) => {
    try {
      const response = await API.apiPost('otpRequest', payload);
      if (response.data && response.data.success) {
        await dispatch({ type: REQUEST_OTP_SUCCESS, payload: response.data });
      }else{
        await dispatch({ type: REQUEST_OTP_FAILED, payload: response.data.message });
      }
    } catch (error) {
      errorRequest(error, dispatch)
    }
  }
}

export const verifyOtp = (payload) => {
  return async (dispatch) => {
    try {
      const response = await API.apiPost('otpverify', payload);
      if (response.data && response.data.success) {
        await dispatch({ type: VERIFY_OTP_SUCCESS, payload: response.data });
      }else{
        await dispatch({ type: VERIFY_OTP_FAILED, payload: response.data.message });
      }
    } catch (error) {
      errorRequest(error, dispatch)
    }
  }
}

export const resetPassword = (payload) => {
  return async (dispatch) => {
    try {
      const response = await API.apiPatch('resetPassword', payload);
      if (response.data && response.data.success) {
        await dispatch({ type: RESET_PASSWORD_SUCCESS, payload: response.data });
      }else{
        await dispatch({ type: RESET_PASSWORD_FAILED, payload: response.data.message });
      }
    } catch (error) {
      errorRequest(error, dispatch)
    }
  }
}

export const authregister =  (payload ) => {
  return async (dispatch) => {
    try { console.log('config is= ',config);
      const response = await API.apiPost('register', payload, config)
      if (response.data && response.data.accessToken) {
        auth.login(response.data.accessToken);
        await dispatch({ type: REGISTER_SUCCESS, payload: response.data.accessToken });
      }else{
        await dispatch({ type: REGISTER_FAILED, payload: response.data.message });
      }
    } catch (err) {
      errorRequest(err, dispatch);
    }
  };
}

