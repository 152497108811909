import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";

const MainFeatures = () => {

    return (
        <>
            <section className="features--section">
                <Container>
                    <Row className="justify-content-center">
                        <Col sm={12} lg={8}>
                            <h2>A World of Options</h2>
                            <p>We’re passionate about making every single image and custom photo album perfect. Our meticulous care and bespoke techniques ensure the highest quality photo printed products, bringing joy for a lifetime.</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12} md={4}>
                            <Card className="mb-4">
                                <Card.Img variant="top" src={require('../../assets/images/photo-album.jpg')} />
                                <Card.Body>
                                    <Card.Title>Auto-Organized Album</Card.Title>
                                    <Card.Text>Some quick example text to build on the card title and make up the bulk of the card's content.</Card.Text>
                                </Card.Body>
                            </Card>
                            <Card>
                                <Card.Img variant="top" src={require('../../assets/images/movies.jpg')} />
                                <Card.Body>
                                    <Card.Title>Comments & Conversation</Card.Title>
                                    <Card.Text>Some quick example text to build on the card title and make up the bulk of the card's content.</Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col sm={12} md={4}>
                            <Card className="mb-4 mt-5">
                                <Card.Img variant="top" src={require('../../assets/images/visitors-wel.jpg')} />
                                <Card.Body>
                                    <Card.Title>Visitors Welcome</Card.Title>
                                    <Card.Text>Some quick example text to build on the card title and make up the bulk of the card's content.</Card.Text>
                                </Card.Body>
                            </Card>
                            <Card>
                                <Card.Img variant="top" src={require('../../assets/images/prints.jpg')} />
                                <Card.Body>
                                    <Card.Title>Get 8 Free Prints</Card.Title>
                                    <Card.Text>Some quick example text to build on the card title and make up the bulk of the card's content.</Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col sm={12} md={4}>
                            <Card className="mb-4">
                                <Card.Img variant="top" src={require('../../assets/images/photobooks.jpg')} />
                                <Card.Body>
                                    <Card.Title>Go Photobooks</Card.Title>
                                    <Card.Text>Some quick example text to build on the card title and make up the bulk of the card's content.</Card.Text>
                                </Card.Body>
                            </Card>
                            <Card>
                                <Card.Img variant="top" src={require('../../assets/images/movies.jpg')} />
                                <Card.Body>
                                    <Card.Title>Grow Up with 1s Movies</Card.Title>
                                    <Card.Text>Some quick example text to build on the card title and make up the bulk of the card's content.</Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default MainFeatures;