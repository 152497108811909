/**
 *  Defined Each API URL as keys to use in project
 */
const apiKeys = {
    login: "/auth/login",
    register: "/auth/register",
    otpRequest:'/auth/otp/request',
    otpverify:'/auth/otp/verify',
    resetPassword:'/auth/password/reset',
    user:'/user',
    gallery : '/gallery',
    invitations: '/invite',
    statistics:'/auth/statistics',
    profile:'/profile',

};
  
export default apiKeys;