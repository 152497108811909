import {useState, useEffect, useRef, React} from "react";
import { Container, Row, Col} from "react-bootstrap";

import TopBar from "components/header/topbar";

import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css';
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { listStatistics } from "redux/actions/dashboard.actions";
import toast, { Toaster } from 'react-hot-toast';
import jwt_decode from 'jwt-decode';
const filterOptions = [
    {label: "Total", value: 'totalCount'},
    {label: "Today's", value: 'today'},
    {label: "Yesterday's", value: 'yesterday'},
    {label: "Current Week", value: 'currentWeek'},
    {label: "Last Week", value: 'lastWeek'},
    {label: "Current Month", value: 'currentMonth'},
    {label: 'Last Month', value: 'lastMonth'},
    {label: 'Since 10 Days', value: 'since10Days'},
    {label: 'Since 15 Days', value: 'since15Days'},
    {label: 'Since 6 Months', value: 'sinceSixMonths'},
    {label: 'Since 1 Year', value: 'sinceOneYear'},
    {label: 'Custom Range', value: 'customRange'}
]



const Dashboard = () => {
    const dashboardError = useSelector(state => state.dashboard.error);
    const [userId, setuserId] = useState('');
    const navigate = useNavigate();
    const dispatch = useDispatch();
    useEffect(() => {
        let token = localStorage.getItem('accessToken')
        let DecodedToken = jwt_decode(token)
        setuserId(DecodedToken.aud)
        dispatch(listStatistics())
    
    }, [])

    useEffect(() => {
       
        
        if(dashboardError){
            toast.dismiss();
            toast.error(dashboardError)
        }

    }, [ dashboardError ])


    return (
        <section className="grid--wrapper">
            <TopBar />
            <div className="grid--stack">
                <Container>
                    <Row>
                        <Col sm={12}>
                            <h2 className="pageTitle">Dashboard</h2>
                        </Col>
                        <h4>No Stats Available</h4>
                    </Row>
                </Container>
            </div>
        </section>
    )
}

export default Dashboard;