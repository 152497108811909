import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FaRegHandshake } from 'react-icons/fa';
import { TbStars, TbTruckDelivery } from 'react-icons/tb';

const HomeSolutions = () => {
    
    return (
        <>
            <section className="features--section bg-light">
                <Container>
                    <Row className="justify-content-center">
                        <Col sm={12} lg={4}>
                            <div className="single--cel">
                                <span><FaRegHandshake/></span>
                                <h3>The Printique Promise</h3>
                                <p>We stand by everything we produce, and nothing is more important to us than your complete satisfaction.</p>
                            </div>
                        </Col>
                        <Col sm={12} lg={4}>
                            <div className="single--cel">
                                <span><TbStars/></span>
                                <h3>Highest Quality Printing</h3>
                                <p>We stand by everything we produce, and nothing is more important to us than your complete satisfaction.</p>
                            </div>
                        </Col>
                        <Col sm={12} lg={4}>
                            <div className="single--cel">
                                <span><TbTruckDelivery/></span>
                                <h3>Fast Shipping</h3>
                                <p>We stand by everything we produce, and nothing is more important to us than your complete satisfaction.</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default HomeSolutions;