import { 
    LIST_GALLERY_INVITATIONS_SUCCESS,
    LIST_GALLERY_INVITATIONS_FAILED,
    GALLERY_INVITATION_ACCEPT_SUCCESS,
    GALLERY_INVITATION_ACCEPT_FAILED
} from "redux/actions/types";

const initialState = {
    error: null,
    createGallery: null,
    invitations: null,
    getInvitationFeed: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    
    case LIST_GALLERY_INVITATIONS_SUCCESS:
        return {
            invitations: action.payload.invitationData
        }
    case LIST_GALLERY_INVITATIONS_FAILED:
        return {
            error: action.payload,
        }
    case GALLERY_INVITATION_ACCEPT_SUCCESS:
        return {
            acceptsuccess: action.payload.message,
        }
    case GALLERY_INVITATION_ACCEPT_FAILED:
        return {
            error: action.payload
        }
    default: return state;
  }
};
