import {
    LOGIN_FAILED,
    LOGIN_SUCCESS,
    LOGOUT,
    LOGIN_COMMON_ERROR,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAILED,
    REQUEST_OTP_SUCCESS,
    REQUEST_OTP_FAILED,
    VERIFY_OTP_SUCCESS,
    VERIFY_OTP_FAILED,
    REGISTER_SUCCESS,
    REGISTER_FAILED
} from "redux/actions/types";
import * as auth from 'helpers/auth';
const token = auth.getToken();

const initialState = {
    token: null,
    loggedIn: token ? true : false,
};

export default (state = initialState, action) => {
    
    switch (action.type) {
        case LOGIN_SUCCESS:
            return {
                token: action.payload,
                loggedIn: true
            };
        case LOGIN_FAILED:
            return {
                error: action.payload,
                loggedIn: false
            };
        case LOGIN_COMMON_ERROR:
            return {
                error: action.payload,
                loggedIn: false
            }
        case REQUEST_OTP_SUCCESS:
            return {
                requestMessage: action.payload.message,
                loggedIn: false
            }
        case REQUEST_OTP_FAILED:
            return {
                error: action.payload,
                loggedIn: false,
            }
        case VERIFY_OTP_SUCCESS:
            return {
                verifyMessage: action.payload.message,
                loggedIn: false,
            }
        case VERIFY_OTP_FAILED:
            return {
                error: action.payload,
                loggedIn: false,
            }
        case RESET_PASSWORD_SUCCESS:
            return {
                updateMessage: action.payload.message,
                loggedIn: false,
            }
        case RESET_PASSWORD_FAILED:
            return {
                error: action.payload,
                loggedIn: false,
            }
        case REGISTER_SUCCESS:
            return {
                token: action.payload,
                loggedIn: true
            };
        case REGISTER_FAILED:
            return {
                error: action.payload,
                loggedIn: token ? true : false
            };
        case LOGOUT: return null;
        default: return state;
    }
};
