import React, { useEffect, useState } from "react";
import { Form, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { resetPassword } from "redux/actions/auth.actions";

const Reset = (props) => {
    const dispatch = useDispatch();
    const [loader, setLoader] = useState(false)
    const [passwordError, setPasswordError] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState("");
    const [passwordInput, setPasswordInput] = useState({
        password: '',
        confirmPassword: ''
    })
    const navigate = useNavigate();

    /** ---- Redux State -- */
    const updateMessage = useSelector(state => state.auth.updateMessage)
    const apiError = useSelector(state => state.auth.error)

    const handlePasswordChange = (evnt) => {
        const passwordInputValue = evnt.target.value.trim();
        const passwordInputFieldName = evnt.target.name;
        const NewPasswordInput = { ...passwordInput, [passwordInputFieldName]: passwordInputValue }
        setPasswordInput(NewPasswordInput);
    }

    const handleValidation = ({ target: { name, value } }) => {
        value = value.trim();
        const passwordInputValue = value;
        const passwordInputFieldName = name;

        /** -- For Password -- */
        if (passwordInputFieldName === 'password') {
            const passwordLength = passwordInputValue.length;

            let errMsg = "";
            if (passwordLength === 0) {
                errMsg = "Password is empty";
            } else {
                errMsg = "";
            }
            setPasswordError(errMsg);
        }

        /** -- For Confirm Password --  */
        if (passwordInputFieldName === "confirmPassword" || (passwordInputFieldName === "password" && passwordInput.confirmPassword.length > 0)) {
            if (passwordInput.confirmPassword !== passwordInput.password) {
                setConfirmPasswordError("Confirm password is not matched");
            } else {
                setConfirmPasswordError("");
            }
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        setLoader(true)
        const payload = {
            password: passwordInput.password,
            email: props.email
        }
        await dispatch(resetPassword(payload))
    }

    useEffect(() => {
        if (updateMessage) {
            toast.success(updateMessage)
            setLoader(false)
            navigate('/login')
        }
        if (apiError) {
            toast.error(updateMessage)
            setLoader(false)
        }
    }, [updateMessage, apiError])

    return (
        <div className="formTop p-4 shadow-lg rounded">
            <div className="text-center">
                <img src={props.logo} className="App-logo mb-3" alt="logo" />
                <h2>Generate New Password</h2>
                {/* <p>Enter your email and we′ll send you instructions to reset your password</p> */}
            </div>
            <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3">
                    <Form.Label>New Password</Form.Label>
                    <Form.Control
                        type="password"
                        placeholder="New Password"
                        name="password"
                        value={passwordInput.password}
                        onChange={handlePasswordChange}
                        onKeyUp={handleValidation}
                    />
                    {passwordError && <span className="text-danger">{passwordError}</span>}
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Confirm New Password</Form.Label>
                    <Form.Control
                        type="password"
                        name="confirmPassword"
                        placeholder="Confirm New Password"
                        value={passwordInput.confirmPassword}
                        onChange={handlePasswordChange}
                        onKeyUp={handleValidation}
                    />
                    {confirmPasswordError && <span className="text-danger">{confirmPasswordError}</span>}
                </Form.Group>
                <div className="text-center">
                    <Button
                        variant="primary"
                        type="submit"
                        disabled={loader}
                    >
                        {loader ? 'Updating...' : 'Update'}
                    </Button>
                </div>
            </Form>
            <Toaster />
        </div>
    )
}

export default Reset;
