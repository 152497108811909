import {useState, useEffect, useRef, React} from "react";
import { Container, Row, Col, Form, Card } from "react-bootstrap";
import Dropdown from 'react-bootstrap/Dropdown';
import { BsTrash } from 'react-icons/bs';
import { AiOutlineEye, AiOutlineEdit } from 'react-icons/ai';
import { FaEllipsisV } from 'react-icons/fa';
import { IoMdShareAlt } from 'react-icons/io';
import { useDispatch, useSelector } from "react-redux";
import { ListGalleries, deleteOneGallery, shareOneGallery, ListsharedGalleries } from "redux/actions/gallery.actions";
import jwt_decode from 'jwt-decode';
import { useNavigate, Link } from "react-router-dom";
// import { searchSvg, goldgym, fitpro, fatfit} from 'components/images';
import Swal from 'sweetalert2';
import toast, { Toaster } from 'react-hot-toast';
import TopBar from "components/header/topbar";


function GalleryList() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [currentPage, setCurrentPage] = useState(1);
    const [ searchTerm, setsearchTerm ] = useState('');
    const [totalPages, setTotalPages] = useState('');
    const [gallery_arr, setGalleryarr] = useState([]);
    const [galleryFeeds, setGalleryFeed] = useState([]);
    const [sharedgalleryFeeds, setSharedGalleryFeed ] = useState([]);
    const galleryFeed = useSelector(state => state.gallery.galleries);
    const sharedgalleryFeed = useSelector(state => state.gallery.sharedgalleries);
    const galleryError = useSelector(state => state.gallery.error);
    const deletedGallery = useSelector(state => state.gallery.deletedGallery);
    const sharedGallery = useSelector(state => state.gallery.sharedGallery);
    const [userId, setuserId] = useState('');
    const pagesToDisplay = [];
    const [activeTab, setActiveTab] = useState("personal"); // "personal" or "shared"
    const handleListGallery = async ( ) => { console.log(activeTab);    
        if( activeTab === "personal" ){
            await dispatch( ListGalleries( currentPage, searchTerm ) );
        }else{
            await dispatch( ListsharedGalleries( currentPage, searchTerm ) );
        }
        
    }

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber); 
    };
    useEffect(() => {
        if( currentPage !== ""){
            if( activeTab === "personal" ){
                dispatch ( ListGalleries( currentPage, searchTerm ) )
            }else{
                dispatch( ListsharedGalleries( currentPage, searchTerm ) );
            }
        }
    }, [currentPage])

    useEffect(() => {
        handleListGallery();
    }, [activeTab])

    useEffect(() => {
        let token = localStorage.getItem('accessToken')
        let DecodedToken = jwt_decode(token)
        setuserId(DecodedToken.aud)
        dispatch(ListGalleries(currentPage))

        console.log('Current Page: ',currentPage);
        const maxVisiblePages = 2; // Maximum number of visible pagination buttons

        

        if (totalPages <= maxVisiblePages) {
            for (let i = 1; i <= totalPages; i++) {
                pagesToDisplay.push(i);
            }
        } else {
            const startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
            const endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

            if (startPage > 1) {
                pagesToDisplay.push(1);
                if (startPage > 2) {
                    pagesToDisplay.push('...');
                }
            }

            for (let i = startPage; i <= endPage; i++) {
                pagesToDisplay.push(i);
            }

            if (endPage < totalPages) {
                if (endPage < totalPages - 1) {
                    pagesToDisplay.push('...');
                }
                pagesToDisplay.push(totalPages);
            }
        }

    }, [])

    useEffect(() => {
        const check = ['undefined', undefined, 'null', null, '']

        if( galleryFeed && galleryFeed.galleryData){
            setGalleryFeed(galleryFeed.galleryData)
            setTotalPages(galleryFeed.totalPages)
        }

        if( sharedgalleryFeed && sharedgalleryFeed.galleryData){
            console.log(sharedgalleryFeed);
            setSharedGalleryFeed(sharedgalleryFeed.galleryData);
            
            setTotalPages(sharedgalleryFeed.totalPages)
        }

    }, [galleryFeed, sharedgalleryFeed])

    

    const handleDeleteGallery = (gallery) => {
        Swal.fire({
            title: "Are you sure?",
            text: "Once deleted, Post will not be able to recover",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
        .then((willDelete) => {
            if (willDelete) {
                //setLoader(true)
                dispatch(deleteOneGallery(gallery._id))
            } 
        });
    }

    

    const handleGalleryShare = ( gallery ) => {
        // Create a custom modal with an email input field
        Swal.fire({
            title: 'Enter Email Address',
            text: 'Please enter email address:',
            input: 'email',
            inputPlaceholder: 'Enter email address',
            showCancelButton: true,
            confirmButtonText: 'Next',
            cancelButtonText: 'Cancel',
            allowOutsideClick: false,
            allowEscapeKey: false,
            showLoaderOnConfirm: true,
          }).then((result) => {
            if (result.isConfirmed) {
              const email = result.value;
             
          
              let payload = {
                'email': email,
                'slug': gallery.slug
              };
          
              // Display loader while waiting for API response
              Swal.showLoading();
          
              // Handle the email input here
              dispatch(shareOneGallery(payload));
              console.log('Entered email:', email);
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              // User clicked Cancel or closed the dialog
              console.log('Email input cancelled');
            }
          });
          
    }

    const handleSearchinput = ( { target: { name, value} } ) => {
        if ( value.length >= 3 ){
            setsearchTerm( value );
        }else if( value.length === 0){
            handleListGallery();
        }
    };

    useEffect(() => {
       
        
        if(galleryError){
            toast.dismiss();
            toast.error(galleryError)
            handleListGallery();
        }

        if(deletedGallery){
            toast.dismiss()
            toast.success(deletedGallery);
            handleListGallery();
        }

        if( searchTerm ){
            handleListGallery();
        }

        if( sharedGallery ){
            toast.dismiss()
            toast.success(sharedGallery);
            Swal.close();
        }

    }, [ galleryError, deletedGallery, searchTerm, sharedGallery ])

    

    // Handle tab change
    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };

    
    return (
        <>
            <section className="grid--wrapper gallery--page">
                <TopBar />
                {/* Add tabs for Personal and Shared Gallery */}
                <div className="grid--stack">
                    <Container>
                        <Row>
                            <Col sm={12} lg={6}>
                                <h2>Galleries</h2>
                            </Col>
                            <Col sm={12} lg={6}>
                                <div className="search--form">
                                    <Form>
                                        <Form.Group className="form-group mb-0 d-md-flex">
                                            <Form.Control type="text" placeholder="Search" onChange={handleSearchinput} />
                                            <Link className="btn btn-primary" to="/gallery/create">Add Gallery</Link>
                                        </Form.Group>
                                    </Form>
                                </div>
                            </Col>
                        </Row>
                        
                        <Row className="mt-4">
                            <Col sm={12}>
                                <div className="tabs">
                                    <button className={`tab ${activeTab === "personal" ? "active" : ""}`} onClick={() => handleTabChange("personal")}>Personal Gallery</button>
                                    <button className={`tab ${activeTab === "shared" ? "active" : ""}`} onClick={() => handleTabChange("shared")}>Shared Gallery</button>
                                </div>
                                <div className="gallery--grid">
                                    <Row>
                                        {
                                            activeTab === "personal" ? (
                                                (galleryFeeds &&  galleryFeeds.length > 0)
                                                    ?   galleryFeeds.map((gallery, ind) => {
                                                            return( <>
                                                                <Col sm={12} lg={4}  key={`gallery-${ind}`}>
                                                                    <Card key={`gal-card-${ind}`}>
                                                                        <Dropdown>
                                                                            <Dropdown.Toggle variant="success" id={`dropdown-basic-${ind}`}>
                                                                                <FaEllipsisV />
                                                                            </Dropdown.Toggle>
                                                                            <Dropdown.Menu>
                                                                                <Dropdown.Item onClick={() => navigate(`/gallery/edit/${gallery.slug}`)}><AiOutlineEdit /> Edit</Dropdown.Item>
                                                                                <Dropdown.Item onClick={() => navigate(`/gallery/view/${gallery.slug}`)}><AiOutlineEye /> View</Dropdown.Item>
                                                                                <Dropdown.Item onClick={() => handleGalleryShare(gallery)}><IoMdShareAlt /> Share</Dropdown.Item>
                                                                                <Dropdown.Item onClick={() => handleDeleteGallery(gallery)}><BsTrash /> Delete</Dropdown.Item>
                                                                            </Dropdown.Menu>
                                                                        </Dropdown>
                                                                        <Card.Img variant="top" src={(gallery.galleryimage) ? gallery.galleryimage.media_url:''} />
                                                                        <Card.Body>
                                                                            <Card.Title>{gallery.name} </Card.Title>
                                                                        </Card.Body>
                                                                    </Card>
                                                                </Col>
                                                            </>)
                                                        })
                                                        :   <h2 className="mt-5 text-center">Galleries Not Found</h2>
                                                    ) : (
                                                        
                                                    (sharedgalleryFeeds &&  sharedgalleryFeeds.length > 0)
                                                    ?   sharedgalleryFeeds.map((shared, ind) => {
                                                            return( <>
                                                                <Col sm={12} lg={4} key={`shared-gallery-${ind}`}>
                                                                    <Card key={`share-card-${ind}`}>
                            
                                                                                <Dropdown>
                                                                                    <Dropdown.Toggle variant="success" id={`dropdown-shared-${ind}`}>
                                                                                        <FaEllipsisV />
                                                                                    </Dropdown.Toggle>
                                                                                    <Dropdown.Menu>
                                                                                        <Dropdown.Item  onClick={() => navigate(`/gallery/edit/${shared.gallery.slug}`)}><AiOutlineEdit /> Edit</Dropdown.Item>
                                                                                        <Dropdown.Item  onClick={() => navigate(`/gallery/view/${shared.gallery.slug}`)}><AiOutlineEdit /> View</Dropdown.Item>
                                                                                    </Dropdown.Menu>
                                                                                </Dropdown>
                                                                                <Card.Img variant="top" src={(shared.gallery.galleryImage.media_url) ? shared.gallery.galleryImage.media_url:''} />
                                                                                <Card.Body>
                                                                                    <Card.Title>{shared.gallery.name} </Card.Title>
                                                                                
                                                                                </Card.Body>
                                                                        
                                                                    </Card>
                                                                </Col>
                                                            </>)
                                                        })
                                                        :   <h2 className="mt-5 text-center">Galleries Not Found</h2>       
                                        )      
                                    }
                           
                                    </Row>

                                </div>
                            </Col>
                        </Row>
                        <div className="pagination">
                            {
                                (currentPage > 1) ? 
                                <button
                                        key={currentPage - 1}
                                        className="pagination-item"
                                        onClick={() => handlePageChange(currentPage - 1)}
                                    >
                                        Prev
                                    </button>
                                : ''
                            }
                            {pagesToDisplay.map((page, index) => (
                                ( totalPages > 1) ?
                                <button
                                    key={index}
                                    className={`pagination-item ${currentPage === page ? 'active' : ''}`}
                                    onClick={() => handlePageChange(page)}
                                >
                                    {typeof page === 'number' ? page : page} {/* Remove the + 1 */}
                                </button>
                                :''
                            ))}

                            {
                                (currentPage < totalPages) ? 
                                <button
                                        key={currentPage + 1}
                                        className="pagination-item"
                                        onClick={() => handlePageChange(currentPage + 1)}
                                    >
                                        Next
                                    </button>
                                : ''
                            }
                        </div>
                        <Toaster />
                    </Container>
                </div>
            </section>
        </>
    )
    
}

export default GalleryList;